import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'react-fa/lib/Icon';
import classNames from 'classnames';

import { pauseTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';
import { gettext } from 'core/utils/text';

import styles from './Styles.scss';

const PauseButton = ({ latestTrackerId, isRunning, pauseTimeTracker }) => (
    <button
        className={classNames(styles.pauseButton, {
            [styles.isRunning]: isRunning,
        })}
        onClick={(e) => {
            e.stopPropagation();
            pauseTimeTracker({ id: latestTrackerId });
        }}
    >
        <Icon
            className={styles.pause}
            name="pause-circle"
            aria-label={gettext('Pause time tracker')}
        />
    </button>
);

PauseButton.propTypes = {
    latestTrackerId: PropTypes.number,
    isRunning: PropTypes.bool,
    pauseTimeTracker: PropTypes.func.isRequired,
};

PauseButton.defaultProps = {
    latestTrackerId: null,
    isRunning: false,
};

export default connect(null, { pauseTimeTracker: pauseTimeTrackerRoutine })(
    PauseButton,
);
