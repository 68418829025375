import { baseQueriesApi as api } from './baseQueriesApi';
export const addTagTypes = [
    'accounts',
    'component-tags',
    'downtime-problem-tags',
    'downtimes',
    'history',
    'machine-mode-switch',
    'measurements',
    'measurements-data',
    'odoo-bill-of-material-component-articles',
    'odoo-manufacturing-order',
    'orders',
    'part-actions',
    'part-blockers',
    'part-orders',
    'part-products',
    'part-templates',
    'part-timeplan',
    'part-types',
    'part-work-plans',
    'pipe-blockers',
    'pipe-field-update',
    'pipe-orders',
    'pipe-pause-problem-tags',
    'pipe-products',
    'pipe-tags',
    'pipe-timeplan',
    'pipe-timeplan-planing-modal',
    'pipes',
    'pipes-export',
    'reports',
    'reports-columns',
    'stations',
    'stations-subscriptions',
    'time-trackers',
    'workplan',
    'machine_data',
    'open-view',
] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            accountsOperatorsList: build.query<
                AccountsOperatorsListApiResponse,
                AccountsOperatorsListApiArg
            >({
                query: () => ({ url: `/api/v1/accounts/operators/` }),
                providesTags: ['accounts'],
            }),
            accountsOperatorsRetrieve: build.query<
                AccountsOperatorsRetrieveApiResponse,
                AccountsOperatorsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/operators/${queryArg.id}/`,
                }),
                providesTags: ['accounts'],
            }),
            accountsOvertimesList: build.query<
                AccountsOvertimesListApiResponse,
                AccountsOvertimesListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/overtimes/`,
                    params: {
                        start_time_after: queryArg.startTimeAfter,
                        start_time_before: queryArg.startTimeBefore,
                    },
                }),
                providesTags: ['accounts'],
            }),
            accountsOvertimesCreate: build.mutation<
                AccountsOvertimesCreateApiResponse,
                AccountsOvertimesCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/overtimes/`,
                    method: 'POST',
                    body: queryArg.overtime,
                }),
                invalidatesTags: ['accounts'],
            }),
            accountsOvertimesRetrieve: build.query<
                AccountsOvertimesRetrieveApiResponse,
                AccountsOvertimesRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/overtimes/${queryArg.id}/`,
                }),
                providesTags: ['accounts'],
            }),
            accountsOvertimesUpdate: build.mutation<
                AccountsOvertimesUpdateApiResponse,
                AccountsOvertimesUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/overtimes/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.overtime,
                }),
                invalidatesTags: ['accounts'],
            }),
            accountsOvertimesPartialUpdate: build.mutation<
                AccountsOvertimesPartialUpdateApiResponse,
                AccountsOvertimesPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/overtimes/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedOvertime,
                }),
                invalidatesTags: ['accounts'],
            }),
            accountsOvertimesDestroy: build.mutation<
                AccountsOvertimesDestroyApiResponse,
                AccountsOvertimesDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/accounts/overtimes/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['accounts'],
            }),
            componentTagsList: build.query<
                ComponentTagsListApiResponse,
                ComponentTagsListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/component-tags/`,
                    params: { search: queryArg.search },
                }),
                providesTags: ['component-tags'],
            }),
            componentTagsRetrieve: build.query<
                ComponentTagsRetrieveApiResponse,
                ComponentTagsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/component-tags/${queryArg.id}/`,
                }),
                providesTags: ['component-tags'],
            }),
            downtimeProblemTagsList: build.query<
                DowntimeProblemTagsListApiResponse,
                DowntimeProblemTagsListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/downtime-problem-tags/`,
                    params: { search: queryArg.search },
                }),
                providesTags: ['downtime-problem-tags'],
            }),
            downtimeProblemTagsRetrieve: build.query<
                DowntimeProblemTagsRetrieveApiResponse,
                DowntimeProblemTagsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/downtime-problem-tags/${queryArg.id}/`,
                }),
                providesTags: ['downtime-problem-tags'],
            }),
            downtimesList: build.query<
                DowntimesListApiResponse,
                DowntimesListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/downtimes/`,
                    params: {
                        work_order_id: queryArg.workOrderId,
                        work_order_id__in: queryArg.workOrderIdIn,
                    },
                }),
                providesTags: ['downtimes'],
            }),
            downtimesCreate: build.mutation<
                DowntimesCreateApiResponse,
                DowntimesCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/downtimes/`,
                    method: 'POST',
                    body: queryArg.downtimeCreate,
                }),
                invalidatesTags: ['downtimes'],
            }),
            downtimesSolvePartialUpdate: build.mutation<
                DowntimesSolvePartialUpdateApiResponse,
                DowntimesSolvePartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/downtimes/${queryArg.id}/solve/`,
                    method: 'PATCH',
                    body: queryArg.patchedDowntimeList,
                }),
                invalidatesTags: ['downtimes'],
            }),
            historyRetrieve: build.query<
                HistoryRetrieveApiResponse,
                HistoryRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/history/${queryArg.id}/`,
                }),
                providesTags: ['history'],
            }),
            machineModeSwitchCreate: build.mutation<
                MachineModeSwitchCreateApiResponse,
                MachineModeSwitchCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/machine-mode-switch/`,
                    method: 'POST',
                    body: queryArg.machineModeSwitchLog,
                }),
                invalidatesTags: ['machine-mode-switch'],
            }),
            measurementsList: build.query<
                MeasurementsListApiResponse,
                MeasurementsListApiArg
            >({
                query: () => ({ url: `/api/v1/measurements/` }),
                providesTags: ['measurements'],
            }),
            measurementsCreate: build.mutation<
                MeasurementsCreateApiResponse,
                MeasurementsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/measurements/`,
                    method: 'POST',
                    body: queryArg.pipeMeasurement,
                }),
                invalidatesTags: ['measurements'],
            }),
            measurementsDataRetrieve: build.query<
                MeasurementsDataRetrieveApiResponse,
                MeasurementsDataRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/measurements-data/${queryArg.id}/`,
                }),
                providesTags: ['measurements-data'],
            }),
            measurementsUpdate: build.mutation<
                MeasurementsUpdateApiResponse,
                MeasurementsUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/measurements/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.pipeMeasurement,
                }),
                invalidatesTags: ['measurements'],
            }),
            measurementsPartialUpdate: build.mutation<
                MeasurementsPartialUpdateApiResponse,
                MeasurementsPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/measurements/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPipeMeasurement,
                }),
                invalidatesTags: ['measurements'],
            }),
            odooBillOfMaterialComponentArticlesList: build.query<
                OdooBillOfMaterialComponentArticlesListApiResponse,
                OdooBillOfMaterialComponentArticlesListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/odoo-bill-of-material-component-articles/`,
                    params: {
                        article_category_id_exact:
                            queryArg.articleCategoryIdExact,
                        default_code_in: queryArg.defaultCodeIn,
                        orphaned: queryArg.orphaned,
                        search: queryArg.search,
                    },
                }),
                providesTags: ['odoo-bill-of-material-component-articles'],
            }),
            odooManufacturingOrderRetrieve: build.query<
                OdooManufacturingOrderRetrieveApiResponse,
                OdooManufacturingOrderRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/odoo-manufacturing-order/` }),
                providesTags: ['odoo-manufacturing-order'],
            }),
            odooManufacturingOrderCreate: build.mutation<
                OdooManufacturingOrderCreateApiResponse,
                OdooManufacturingOrderCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/odoo-manufacturing-order/`,
                    method: 'POST',
                    body: queryArg.manufacturingOrder,
                }),
                invalidatesTags: ['odoo-manufacturing-order'],
            }),
            odooManufacturingOrderUpdate: build.mutation<
                OdooManufacturingOrderUpdateApiResponse,
                OdooManufacturingOrderUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/odoo-manufacturing-order/`,
                    method: 'PUT',
                    body: queryArg.manufacturingOrderUpdate,
                }),
                invalidatesTags: ['odoo-manufacturing-order'],
            }),
            odooManufacturingOrderDestroy: build.mutation<
                OdooManufacturingOrderDestroyApiResponse,
                OdooManufacturingOrderDestroyApiArg
            >({
                query: () => ({
                    url: `/api/v1/odoo-manufacturing-order/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['odoo-manufacturing-order'],
            }),
            ordersAlwaysInSystemUpdate: build.mutation<
                OrdersAlwaysInSystemUpdateApiResponse,
                OrdersAlwaysInSystemUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/orders/always-in-system/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.orderAlwaysInTheSystemUpdateField,
                }),
                invalidatesTags: ['orders'],
            }),
            partActionsList: build.query<
                PartActionsListApiResponse,
                PartActionsListApiArg
            >({
                query: () => ({ url: `/api/v1/part-actions/` }),
                providesTags: ['part-actions'],
            }),
            partActionsRetrieve: build.query<
                PartActionsRetrieveApiResponse,
                PartActionsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-actions/${queryArg.id}/`,
                }),
                providesTags: ['part-actions'],
            }),
            partBlockersList: build.query<
                PartBlockersListApiResponse,
                PartBlockersListApiArg
            >({
                query: () => ({ url: `/api/v1/part-blockers/` }),
                providesTags: ['part-blockers'],
            }),
            partBlockersCreate: build.mutation<
                PartBlockersCreateApiResponse,
                PartBlockersCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-blockers/`,
                    method: 'POST',
                    body: queryArg.partBlocker,
                }),
                invalidatesTags: ['part-blockers'],
            }),
            partBlockersRetrieve: build.query<
                PartBlockersRetrieveApiResponse,
                PartBlockersRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-blockers/${queryArg.id}/`,
                }),
                providesTags: ['part-blockers'],
            }),
            partBlockersUpdate: build.mutation<
                PartBlockersUpdateApiResponse,
                PartBlockersUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-blockers/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.partBlocker,
                }),
                invalidatesTags: ['part-blockers'],
            }),
            partBlockersPartialUpdate: build.mutation<
                PartBlockersPartialUpdateApiResponse,
                PartBlockersPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-blockers/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPartBlocker,
                }),
                invalidatesTags: ['part-blockers'],
            }),
            partBlockersDestroy: build.mutation<
                PartBlockersDestroyApiResponse,
                PartBlockersDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-blockers/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['part-blockers'],
            }),
            partBlockersBulkDeleteDestroy: build.mutation<
                PartBlockersBulkDeleteDestroyApiResponse,
                PartBlockersBulkDeleteDestroyApiArg
            >({
                query: () => ({
                    url: `/api/v1/part-blockers/bulk_delete/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['part-blockers'],
            }),
            partOrdersList: build.query<
                PartOrdersListApiResponse,
                PartOrdersListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-orders/`,
                    params: { cursor: queryArg.cursor },
                }),
                providesTags: ['part-orders'],
            }),
            partOrdersRetrieve: build.query<
                PartOrdersRetrieveApiResponse,
                PartOrdersRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-orders/${queryArg.id}/`,
                }),
                providesTags: ['part-orders'],
            }),
            partProductsList: build.query<
                PartProductsListApiResponse,
                PartProductsListApiArg
            >({
                query: () => ({ url: `/api/v1/part-products/` }),
                providesTags: ['part-products'],
            }),
            partProductsCreate: build.mutation<
                PartProductsCreateApiResponse,
                PartProductsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/`,
                    method: 'POST',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsRetrieve: build.query<
                PartProductsRetrieveApiResponse,
                PartProductsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/${queryArg.id}/`,
                }),
                providesTags: ['part-products'],
            }),
            partProductsUpdate: build.mutation<
                PartProductsUpdateApiResponse,
                PartProductsUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsPartialUpdate: build.mutation<
                PartProductsPartialUpdateApiResponse,
                PartProductsPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPartProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsDestroy: build.mutation<
                PartProductsDestroyApiResponse,
                PartProductsDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsActivateCreate: build.mutation<
                PartProductsActivateCreateApiResponse,
                PartProductsActivateCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/activate/`,
                    method: 'POST',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsBulkUpdateUpdate: build.mutation<
                PartProductsBulkUpdateUpdateApiResponse,
                PartProductsBulkUpdateUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/bulk_update/`,
                    method: 'PUT',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsBulkUpdatePartialUpdate: build.mutation<
                PartProductsBulkUpdatePartialUpdateApiResponse,
                PartProductsBulkUpdatePartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/bulk_update/`,
                    method: 'PATCH',
                    body: queryArg.patchedPartProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsDeactivateCreate: build.mutation<
                PartProductsDeactivateCreateApiResponse,
                PartProductsDeactivateCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/deactivate/`,
                    method: 'POST',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsOverlappingActionsRetrieve: build.query<
                PartProductsOverlappingActionsRetrieveApiResponse,
                PartProductsOverlappingActionsRetrieveApiArg
            >({
                query: () => ({
                    url: `/api/v1/part-products/overlapping_actions/`,
                }),
                providesTags: ['part-products'],
            }),
            partProductsPlanCreate: build.mutation<
                PartProductsPlanCreateApiResponse,
                PartProductsPlanCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/plan/`,
                    method: 'POST',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsRevokeCreate: build.mutation<
                PartProductsRevokeCreateApiResponse,
                PartProductsRevokeCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/revoke/`,
                    method: 'POST',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partProductsUnplanCreate: build.mutation<
                PartProductsUnplanCreateApiResponse,
                PartProductsUnplanCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-products/unplan/`,
                    method: 'POST',
                    body: queryArg.partProductList,
                }),
                invalidatesTags: ['part-products'],
            }),
            partTemplatesList: build.query<
                PartTemplatesListApiResponse,
                PartTemplatesListApiArg
            >({
                query: () => ({ url: `/api/v1/part-templates/` }),
                providesTags: ['part-templates'],
            }),
            partTemplatesCreate: build.mutation<
                PartTemplatesCreateApiResponse,
                PartTemplatesCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-templates/`,
                    method: 'POST',
                    body: queryArg.partTemplate,
                }),
                invalidatesTags: ['part-templates'],
            }),
            partTemplatesRetrieve: build.query<
                PartTemplatesRetrieveApiResponse,
                PartTemplatesRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-templates/${queryArg.id}/`,
                }),
                providesTags: ['part-templates'],
            }),
            partTemplatesUpdate: build.mutation<
                PartTemplatesUpdateApiResponse,
                PartTemplatesUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-templates/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.partTemplate,
                }),
                invalidatesTags: ['part-templates'],
            }),
            partTemplatesPartialUpdate: build.mutation<
                PartTemplatesPartialUpdateApiResponse,
                PartTemplatesPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-templates/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPartTemplate,
                }),
                invalidatesTags: ['part-templates'],
            }),
            partTemplatesDestroy: build.mutation<
                PartTemplatesDestroyApiResponse,
                PartTemplatesDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-templates/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['part-templates'],
            }),
            partTimeplanList: build.query<
                PartTimeplanListApiResponse,
                PartTimeplanListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-timeplan/`,
                    params: { cursor: queryArg.cursor },
                }),
                providesTags: ['part-timeplan'],
            }),
            partTimeplanRetrieve: build.query<
                PartTimeplanRetrieveApiResponse,
                PartTimeplanRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-timeplan/${queryArg.id}/`,
                }),
                providesTags: ['part-timeplan'],
            }),
            partTypesList: build.query<
                PartTypesListApiResponse,
                PartTypesListApiArg
            >({
                query: () => ({ url: `/api/v1/part-types/` }),
                providesTags: ['part-types'],
            }),
            partTypesRetrieve: build.query<
                PartTypesRetrieveApiResponse,
                PartTypesRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/part-types/${queryArg.id}/`,
                }),
                providesTags: ['part-types'],
            }),
            partWorkPlansRetrieve: build.query<
                PartWorkPlansRetrieveApiResponse,
                PartWorkPlansRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/part-work-plans/` }),
                providesTags: ['part-work-plans'],
            }),
            pipeBlockersList: build.query<
                PipeBlockersListApiResponse,
                PipeBlockersListApiArg
            >({
                query: () => ({ url: `/api/v1/pipe-blockers/` }),
                providesTags: ['pipe-blockers'],
            }),
            pipeBlockersCreate: build.mutation<
                PipeBlockersCreateApiResponse,
                PipeBlockersCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-blockers/`,
                    method: 'POST',
                    body: queryArg.pipeBlocker,
                }),
                invalidatesTags: ['pipe-blockers'],
            }),
            pipeBlockersRetrieve: build.query<
                PipeBlockersRetrieveApiResponse,
                PipeBlockersRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-blockers/${queryArg.id}/`,
                }),
                providesTags: ['pipe-blockers'],
            }),
            pipeBlockersUpdate: build.mutation<
                PipeBlockersUpdateApiResponse,
                PipeBlockersUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-blockers/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.pipeBlocker,
                }),
                invalidatesTags: ['pipe-blockers'],
            }),
            pipeBlockersPartialUpdate: build.mutation<
                PipeBlockersPartialUpdateApiResponse,
                PipeBlockersPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-blockers/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPipeBlocker,
                }),
                invalidatesTags: ['pipe-blockers'],
            }),
            pipeBlockersDestroy: build.mutation<
                PipeBlockersDestroyApiResponse,
                PipeBlockersDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-blockers/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['pipe-blockers'],
            }),
            pipeBlockersBulkDeleteDestroy: build.mutation<
                PipeBlockersBulkDeleteDestroyApiResponse,
                PipeBlockersBulkDeleteDestroyApiArg
            >({
                query: () => ({
                    url: `/api/v1/pipe-blockers/bulk_delete/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['pipe-blockers'],
            }),
            pipeFieldUpdateUpdate: build.mutation<
                PipeFieldUpdateUpdateApiResponse,
                PipeFieldUpdateUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-field-update/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.pipeUpdateField,
                }),
                invalidatesTags: ['pipe-field-update'],
            }),
            pipeOrdersList: build.query<
                PipeOrdersListApiResponse,
                PipeOrdersListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-orders/`,
                    params: { cursor: queryArg.cursor },
                }),
                providesTags: ['pipe-orders'],
            }),
            pipeOrdersRetrieve: build.query<
                PipeOrdersRetrieveApiResponse,
                PipeOrdersRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-orders/${queryArg.id}/`,
                }),
                providesTags: ['pipe-orders'],
            }),
            pipePauseProblemTagsList: build.query<
                PipePauseProblemTagsListApiResponse,
                PipePauseProblemTagsListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-pause-problem-tags/`,
                    params: { search: queryArg.search },
                }),
                providesTags: ['pipe-pause-problem-tags'],
            }),
            pipePauseProblemTagsRetrieve: build.query<
                PipePauseProblemTagsRetrieveApiResponse,
                PipePauseProblemTagsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-pause-problem-tags/${queryArg.id}/`,
                }),
                providesTags: ['pipe-pause-problem-tags'],
            }),
            pipeProductsList: build.query<
                PipeProductsListApiResponse,
                PipeProductsListApiArg
            >({
                query: () => ({ url: `/api/v1/pipe-products/` }),
                providesTags: ['pipe-products'],
            }),
            pipeProductsCreate: build.mutation<
                PipeProductsCreateApiResponse,
                PipeProductsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsRetrieve: build.query<
                PipeProductsRetrieveApiResponse,
                PipeProductsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/`,
                }),
                providesTags: ['pipe-products'],
            }),
            pipeProductsUpdate: build.mutation<
                PipeProductsUpdateApiResponse,
                PipeProductsUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsPartialUpdate: build.mutation<
                PipeProductsPartialUpdateApiResponse,
                PipeProductsPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsDestroy: build.mutation<
                PipeProductsDestroyApiResponse,
                PipeProductsDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsCancelPredictionCreate: build.mutation<
                PipeProductsCancelPredictionCreateApiResponse,
                PipeProductsCancelPredictionCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/cancel_prediction/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsConfigureCreate: build.mutation<
                PipeProductsConfigureCreateApiResponse,
                PipeProductsConfigureCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/configure/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsEndCreate: build.mutation<
                PipeProductsEndCreateApiResponse,
                PipeProductsEndCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/end/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsFaultyStatsRetrieve: build.query<
                PipeProductsFaultyStatsRetrieveApiResponse,
                PipeProductsFaultyStatsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/faulty_stats/`,
                }),
                providesTags: ['pipe-products'],
            }),
            pipeProductsMultiStageCreate: build.mutation<
                PipeProductsMultiStageCreateApiResponse,
                PipeProductsMultiStageCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/multi_stage/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsPauseCreate: build.mutation<
                PipeProductsPauseCreateApiResponse,
                PipeProductsPauseCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/pause/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsPipeRetrieve: build.query<
                PipeProductsPipeRetrieveApiResponse,
                PipeProductsPipeRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/pipe/`,
                }),
                providesTags: ['pipe-products'],
            }),
            pipeProductsSplitCreate: build.mutation<
                PipeProductsSplitCreateApiResponse,
                PipeProductsSplitCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/split/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsStartCreate: build.mutation<
                PipeProductsStartCreateApiResponse,
                PipeProductsStartCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/${queryArg.id}/start/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsActivateCreate: build.mutation<
                PipeProductsActivateCreateApiResponse,
                PipeProductsActivateCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/activate/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsBulkUpdateUpdate: build.mutation<
                PipeProductsBulkUpdateUpdateApiResponse,
                PipeProductsBulkUpdateUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/bulk_update/`,
                    method: 'PUT',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsBulkUpdatePartialUpdate: build.mutation<
                PipeProductsBulkUpdatePartialUpdateApiResponse,
                PipeProductsBulkUpdatePartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/bulk_update/`,
                    method: 'PATCH',
                    body: queryArg.patchedPipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsDeactivateCreate: build.mutation<
                PipeProductsDeactivateCreateApiResponse,
                PipeProductsDeactivateCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/deactivate/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsMergeCreate: build.mutation<
                PipeProductsMergeCreateApiResponse,
                PipeProductsMergeCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/merge/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsPlanCreate: build.mutation<
                PipeProductsPlanCreateApiResponse,
                PipeProductsPlanCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/plan/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeProductsPlannedRetrieve: build.query<
                PipeProductsPlannedRetrieveApiResponse,
                PipeProductsPlannedRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/pipe-products/planned/` }),
                providesTags: ['pipe-products'],
            }),
            pipeProductsUnplanCreate: build.mutation<
                PipeProductsUnplanCreateApiResponse,
                PipeProductsUnplanCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-products/unplan/`,
                    method: 'POST',
                    body: queryArg.pipeProductList,
                }),
                invalidatesTags: ['pipe-products'],
            }),
            pipeTagsList: build.query<
                PipeTagsListApiResponse,
                PipeTagsListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-tags/`,
                    params: {
                        search: queryArg.search,
                        show_in_list_initially: queryArg.showInListInitially,
                    },
                }),
                providesTags: ['pipe-tags'],
            }),
            pipeTagsRetrieve: build.query<
                PipeTagsRetrieveApiResponse,
                PipeTagsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-tags/${queryArg.id}/`,
                }),
                providesTags: ['pipe-tags'],
            }),
            pipeTimeplanList: build.query<
                PipeTimeplanListApiResponse,
                PipeTimeplanListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-timeplan/`,
                    params: { cursor: queryArg.cursor },
                }),
                providesTags: ['pipe-timeplan'],
            }),
            pipeTimeplanPlaningModalList: build.query<
                PipeTimeplanPlaningModalListApiResponse,
                PipeTimeplanPlaningModalListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-timeplan-planing-modal/`,
                    params: { cursor: queryArg.cursor },
                }),
                providesTags: ['pipe-timeplan-planing-modal'],
            }),
            pipeTimeplanPlaningModalRetrieve: build.query<
                PipeTimeplanPlaningModalRetrieveApiResponse,
                PipeTimeplanPlaningModalRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-timeplan-planing-modal/${queryArg.id}/`,
                }),
                providesTags: ['pipe-timeplan-planing-modal'],
            }),
            pipeTimeplanRetrieve: build.query<
                PipeTimeplanRetrieveApiResponse,
                PipeTimeplanRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipe-timeplan/${queryArg.id}/`,
                }),
                providesTags: ['pipe-timeplan'],
            }),
            pipesList: build.query<PipesListApiResponse, PipesListApiArg>({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/`,
                    params: { cursor: queryArg.cursor },
                }),
                providesTags: ['pipes'],
            }),
            pipesCreate: build.mutation<
                PipesCreateApiResponse,
                PipesCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/`,
                    method: 'POST',
                    body: queryArg.pipeUpdate,
                }),
                invalidatesTags: ['pipes'],
            }),
            pipesExportRetrieve: build.query<
                PipesExportRetrieveApiResponse,
                PipesExportRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/pipes-export/` }),
                providesTags: ['pipes-export'],
            }),
            pipesExportCreate: build.mutation<
                PipesExportCreateApiResponse,
                PipesExportCreateApiArg
            >({
                query: () => ({ url: `/api/v1/pipes-export/`, method: 'POST' }),
                invalidatesTags: ['pipes-export'],
            }),
            pipesRetrieve: build.query<
                PipesRetrieveApiResponse,
                PipesRetrieveApiArg
            >({
                query: (queryArg) => ({ url: `/api/v1/pipes/${queryArg.id}/` }),
                providesTags: ['pipes'],
            }),
            pipesUpdate: build.mutation<
                PipesUpdateApiResponse,
                PipesUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.pipeUpdate,
                }),
                invalidatesTags: ['pipes'],
            }),
            pipesPartialUpdate: build.mutation<
                PipesPartialUpdateApiResponse,
                PipesPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedPipeUpdate,
                }),
                invalidatesTags: ['pipes'],
            }),
            pipesDestroy: build.mutation<
                PipesDestroyApiResponse,
                PipesDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['pipes'],
            }),
            pipesOdooManualConfirmUpdate: build.mutation<
                PipesOdooManualConfirmUpdateApiResponse,
                PipesOdooManualConfirmUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/${queryArg.id}/odoo_manual_confirm/`,
                    method: 'PUT',
                    body: queryArg.pipeUpdate,
                }),
                invalidatesTags: ['pipes'],
            }),
            pipesProduceNewUpdate: build.mutation<
                PipesProduceNewUpdateApiResponse,
                PipesProduceNewUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/pipes/${queryArg.id}/produce_new/`,
                    method: 'PUT',
                    body: queryArg.pipeUpdate,
                }),
                invalidatesTags: ['pipes'],
            }),
            reportsList: build.query<ReportsListApiResponse, ReportsListApiArg>(
                {
                    query: () => ({ url: `/api/v1/reports/` }),
                    providesTags: ['reports'],
                }
            ),
            reportsColumnsRetrieve: build.query<
                ReportsColumnsRetrieveApiResponse,
                ReportsColumnsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/reports-columns/${queryArg.tab}/`,
                }),
                providesTags: ['reports-columns'],
            }),
            reportsColumnsCreate: build.mutation<
                ReportsColumnsCreateApiResponse,
                ReportsColumnsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/reports-columns/${queryArg.tab}/`,
                    method: 'POST',
                }),
                invalidatesTags: ['reports-columns'],
            }),
            reportsRetrieve: build.query<
                ReportsRetrieveApiResponse,
                ReportsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/reports/${queryArg.id}/`,
                }),
                providesTags: ['reports'],
            }),
            reportsCertifiedRetrieve: build.query<
                ReportsCertifiedRetrieveApiResponse,
                ReportsCertifiedRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/certified/` }),
                providesTags: ['reports'],
            }),
            reportsFaultsRetrieve: build.query<
                ReportsFaultsRetrieveApiResponse,
                ReportsFaultsRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/faults/` }),
                providesTags: ['reports'],
            }),
            reportsMaterialPlanRetrieve: build.query<
                ReportsMaterialPlanRetrieveApiResponse,
                ReportsMaterialPlanRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/material_plan/` }),
                providesTags: ['reports'],
            }),
            reportsMaterialsRetrieve: build.query<
                ReportsMaterialsRetrieveApiResponse,
                ReportsMaterialsRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/materials/` }),
                providesTags: ['reports'],
            }),
            reportsOverviewRetrieve: build.query<
                ReportsOverviewRetrieveApiResponse,
                ReportsOverviewRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/overview/` }),
                providesTags: ['reports'],
            }),
            reportsTagGroupsRetrieve: build.query<
                ReportsTagGroupsRetrieveApiResponse,
                ReportsTagGroupsRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/tag_groups/` }),
                providesTags: ['reports'],
            }),
            reportsTagsRetrieve: build.query<
                ReportsTagsRetrieveApiResponse,
                ReportsTagsRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/reports/tags/` }),
                providesTags: ['reports'],
            }),
            stationsList: build.query<
                StationsListApiResponse,
                StationsListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations/`,
                    params: {
                        station_type: queryArg.stationType,
                        station_type__in: queryArg.stationTypeIn,
                    },
                }),
                providesTags: ['stations'],
            }),
            stationsCreate: build.mutation<
                StationsCreateApiResponse,
                StationsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations/`,
                    method: 'POST',
                    body: queryArg.station,
                }),
                invalidatesTags: ['stations'],
            }),
            stationsSubscriptionsList: build.query<
                StationsSubscriptionsListApiResponse,
                StationsSubscriptionsListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/`,
                    params: {
                        cursor: queryArg.cursor,
                        end_time: queryArg.endTime,
                        start_time: queryArg.startTime,
                        stations: queryArg.stations,
                        subscriber_object_id: queryArg.subscriberObjectId,
                        subscriber_type: queryArg.subscriberType,
                    },
                }),
                providesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsCreate: build.mutation<
                StationsSubscriptionsCreateApiResponse,
                StationsSubscriptionsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/`,
                    method: 'POST',
                    body: queryArg.stationSubscriptionSerializerList,
                }),
                invalidatesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsRetrieve: build.query<
                StationsSubscriptionsRetrieveApiResponse,
                StationsSubscriptionsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/${queryArg.id}/`,
                }),
                providesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsUpdate: build.mutation<
                StationsSubscriptionsUpdateApiResponse,
                StationsSubscriptionsUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.stationSubscriptionSerializerList,
                }),
                invalidatesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsPartialUpdate: build.mutation<
                StationsSubscriptionsPartialUpdateApiResponse,
                StationsSubscriptionsPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedStationSubscriptionSerializerList,
                }),
                invalidatesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsDestroy: build.mutation<
                StationsSubscriptionsDestroyApiResponse,
                StationsSubscriptionsDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsChangeStationsCreate: build.mutation<
                StationsSubscriptionsChangeStationsCreateApiResponse,
                StationsSubscriptionsChangeStationsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/${queryArg.id}/change_stations/`,
                    method: 'POST',
                    body: queryArg.stationSubscriptionSerializerList,
                }),
                invalidatesTags: ['stations-subscriptions'],
            }),
            stationsSubscriptionsStopCreate: build.mutation<
                StationsSubscriptionsStopCreateApiResponse,
                StationsSubscriptionsStopCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations-subscriptions/${queryArg.id}/stop/`,
                    method: 'POST',
                    body: queryArg.stationSubscriptionSerializerList,
                }),
                invalidatesTags: ['stations-subscriptions'],
            }),
            stationsRetrieve: build.query<
                StationsRetrieveApiResponse,
                StationsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations/${queryArg.id}/`,
                }),
                providesTags: ['stations'],
            }),
            stationsUpdate: build.mutation<
                StationsUpdateApiResponse,
                StationsUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.station,
                }),
                invalidatesTags: ['stations'],
            }),
            stationsPartialUpdate: build.mutation<
                StationsPartialUpdateApiResponse,
                StationsPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedStation,
                }),
                invalidatesTags: ['stations'],
            }),
            stationsDestroy: build.mutation<
                StationsDestroyApiResponse,
                StationsDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/stations/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['stations'],
            }),
            timeTrackersList: build.query<
                TimeTrackersListApiResponse,
                TimeTrackersListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/`,
                    params: {
                        work_order_id: queryArg.workOrderId,
                        work_order_id__in: queryArg.workOrderIdIn,
                    },
                }),
                providesTags: ['time-trackers'],
            }),
            timeTrackersCreate: build.mutation<
                TimeTrackersCreateApiResponse,
                TimeTrackersCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/`,
                    method: 'POST',
                    body: queryArg.timeTracker,
                }),
                invalidatesTags: ['time-trackers'],
            }),
            timeTrackersRetrieve: build.query<
                TimeTrackersRetrieveApiResponse,
                TimeTrackersRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/${queryArg.id}/`,
                }),
                providesTags: ['time-trackers'],
            }),
            timeTrackersUpdate: build.mutation<
                TimeTrackersUpdateApiResponse,
                TimeTrackersUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.timeTracker,
                }),
                invalidatesTags: ['time-trackers'],
            }),
            timeTrackersPartialUpdate: build.mutation<
                TimeTrackersPartialUpdateApiResponse,
                TimeTrackersPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedTimeTracker,
                }),
                invalidatesTags: ['time-trackers'],
            }),
            timeTrackersDestroy: build.mutation<
                TimeTrackersDestroyApiResponse,
                TimeTrackersDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['time-trackers'],
            }),
            timeTrackersPausePartialUpdate: build.mutation<
                TimeTrackersPausePartialUpdateApiResponse,
                TimeTrackersPausePartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/${queryArg.id}/pause/`,
                    method: 'PATCH',
                    body: queryArg.patchedTimeTracker,
                }),
                invalidatesTags: ['time-trackers'],
            }),
            timeTrackersStopPartialUpdate: build.mutation<
                TimeTrackersStopPartialUpdateApiResponse,
                TimeTrackersStopPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/time-trackers/${queryArg.id}/stop/`,
                    method: 'PATCH',
                    body: queryArg.patchedTimeTracker,
                }),
                invalidatesTags: ['time-trackers'],
            }),
            workplanBodyExpansionTypesList: build.query<
                WorkplanBodyExpansionTypesListApiResponse,
                WorkplanBodyExpansionTypesListApiArg
            >({
                query: () => ({
                    url: `/api/v1/workplan/body-expansion-types/`,
                }),
                providesTags: ['workplan'],
            }),
            workplanBodyExpansionTypesRetrieve: build.query<
                WorkplanBodyExpansionTypesRetrieveApiResponse,
                WorkplanBodyExpansionTypesRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/body-expansion-types/${queryArg.id}/`,
                }),
                providesTags: ['workplan'],
            }),
            workplanCuttingOptionsRetrieve: build.query<
                WorkplanCuttingOptionsRetrieveApiResponse,
                WorkplanCuttingOptionsRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/workplan/cutting-options/` }),
                providesTags: ['workplan'],
            }),
            workplanFormulaCalculationRetrieve: build.query<
                WorkplanFormulaCalculationRetrieveApiResponse,
                WorkplanFormulaCalculationRetrieveApiArg
            >({
                query: () => ({ url: `/api/v1/workplan/formula-calculation/` }),
                providesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsList: build.query<
                WorkplanPartWorkorderActionsListApiResponse,
                WorkplanPartWorkorderActionsListApiArg
            >({
                query: () => ({
                    url: `/api/v1/workplan/part-workorder-actions/`,
                }),
                providesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsCreate: build.mutation<
                WorkplanPartWorkorderActionsCreateApiResponse,
                WorkplanPartWorkorderActionsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/`,
                    method: 'POST',
                    body: queryArg.workOrderAction,
                }),
                invalidatesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsRetrieve: build.query<
                WorkplanPartWorkorderActionsRetrieveApiResponse,
                WorkplanPartWorkorderActionsRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/${queryArg.id}/`,
                }),
                providesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsUpdate: build.mutation<
                WorkplanPartWorkorderActionsUpdateApiResponse,
                WorkplanPartWorkorderActionsUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/${queryArg.id}/`,
                    method: 'PUT',
                    body: queryArg.workOrderAction,
                }),
                invalidatesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsPartialUpdate: build.mutation<
                WorkplanPartWorkorderActionsPartialUpdateApiResponse,
                WorkplanPartWorkorderActionsPartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/${queryArg.id}/`,
                    method: 'PATCH',
                    body: queryArg.patchedWorkOrderAction,
                }),
                invalidatesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsDestroy: build.mutation<
                WorkplanPartWorkorderActionsDestroyApiResponse,
                WorkplanPartWorkorderActionsDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/${queryArg.id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsBulkUpdateUpdate: build.mutation<
                WorkplanPartWorkorderActionsBulkUpdateUpdateApiResponse,
                WorkplanPartWorkorderActionsBulkUpdateUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/bulk_update/`,
                    method: 'PUT',
                    body: queryArg.workOrderAction,
                }),
                invalidatesTags: ['workplan'],
            }),
            workplanPartWorkorderActionsBulkUpdatePartialUpdate: build.mutation<
                WorkplanPartWorkorderActionsBulkUpdatePartialUpdateApiResponse,
                WorkplanPartWorkorderActionsBulkUpdatePartialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorder-actions/bulk_update/`,
                    method: 'PATCH',
                    body: queryArg.patchedWorkOrderAction,
                }),
                invalidatesTags: ['workplan'],
            }),
            workplanPartWorkordersList: build.query<
                WorkplanPartWorkordersListApiResponse,
                WorkplanPartWorkordersListApiArg
            >({
                query: () => ({ url: `/api/v1/workplan/part-workorders/` }),
                providesTags: ['workplan'],
            }),
            workplanPartWorkordersRetrieve: build.query<
                WorkplanPartWorkordersRetrieveApiResponse,
                WorkplanPartWorkordersRetrieveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/workplan/part-workorders/${queryArg.id}/`,
                }),
                providesTags: ['workplan'],
            }),
            machineDataElikoMandrelActionCreate: build.mutation<
                MachineDataElikoMandrelActionCreateApiResponse,
                MachineDataElikoMandrelActionCreateApiArg
            >({
                query: () => ({
                    url: `/machine_data/eliko/mandrel_action/`,
                    method: 'POST',
                }),
                invalidatesTags: ['machine_data'],
            }),
            machineDataElikoMandrelActionManyCreate: build.mutation<
                MachineDataElikoMandrelActionManyCreateApiResponse,
                MachineDataElikoMandrelActionManyCreateApiArg
            >({
                query: () => ({
                    url: `/machine_data/eliko/mandrel_action/many/`,
                    method: 'POST',
                }),
                invalidatesTags: ['machine_data'],
            }),
            machineDataMandrelActionCreate: build.mutation<
                MachineDataMandrelActionCreateApiResponse,
                MachineDataMandrelActionCreateApiArg
            >({
                query: () => ({
                    url: `/machine_data/mandrel_action/`,
                    method: 'POST',
                }),
                invalidatesTags: ['machine_data'],
            }),
            machineDataMandrelActionManyCreate: build.mutation<
                MachineDataMandrelActionManyCreateApiResponse,
                MachineDataMandrelActionManyCreateApiArg
            >({
                query: () => ({
                    url: `/machine_data/mandrel_action/many/`,
                    method: 'POST',
                }),
                invalidatesTags: ['machine_data'],
            }),
            machineDataPipeProductionDataCreate: build.mutation<
                MachineDataPipeProductionDataCreateApiResponse,
                MachineDataPipeProductionDataCreateApiArg
            >({
                query: () => ({
                    url: `/machine_data/pipe_production_data/`,
                    method: 'POST',
                }),
                invalidatesTags: ['machine_data'],
            }),
            machineDataPipeProductionDataManyCreate: build.mutation<
                MachineDataPipeProductionDataManyCreateApiResponse,
                MachineDataPipeProductionDataManyCreateApiArg
            >({
                query: () => ({
                    url: `/machine_data/pipe_production_data/many/`,
                    method: 'POST',
                }),
                invalidatesTags: ['machine_data'],
            }),
            openViewRetrieve: build.query<
                OpenViewRetrieveApiResponse,
                OpenViewRetrieveApiArg
            >({
                query: () => ({ url: `/open-view/` }),
                providesTags: ['open-view'],
            }),
            openViewCreate: build.mutation<
                OpenViewCreateApiResponse,
                OpenViewCreateApiArg
            >({
                query: () => ({ url: `/open-view/`, method: 'POST' }),
                invalidatesTags: ['open-view'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as queriesApi };
export type AccountsOperatorsListApiResponse = /** status 200  */ Operator[];
export type AccountsOperatorsListApiArg = void;
export type AccountsOperatorsRetrieveApiResponse = /** status 200  */ Operator;
export type AccountsOperatorsRetrieveApiArg = {
    id: string;
};
export type AccountsOvertimesListApiResponse = /** status 200  */ Overtime[];
export type AccountsOvertimesListApiArg = {
    startTimeAfter?: string;
    startTimeBefore?: string;
};
export type AccountsOvertimesCreateApiResponse = /** status 201  */ Overtime;
export type AccountsOvertimesCreateApiArg = {
    overtime: Overtime;
};
export type AccountsOvertimesRetrieveApiResponse = /** status 200  */ Overtime;
export type AccountsOvertimesRetrieveApiArg = {
    /** A unique integer value identifying this Ületunnitöö. */
    id: number;
};
export type AccountsOvertimesUpdateApiResponse = /** status 200  */ Overtime;
export type AccountsOvertimesUpdateApiArg = {
    /** A unique integer value identifying this Ületunnitöö. */
    id: number;
    overtime: Overtime;
};
export type AccountsOvertimesPartialUpdateApiResponse = /** status 200  */ Overtime;
export type AccountsOvertimesPartialUpdateApiArg = {
    /** A unique integer value identifying this Ületunnitöö. */
    id: number;
    patchedOvertime: PatchedOvertime;
};
export type AccountsOvertimesDestroyApiResponse = unknown;
export type AccountsOvertimesDestroyApiArg = {
    /** A unique integer value identifying this Ületunnitöö. */
    id: number;
};
export type ComponentTagsListApiResponse = /** status 200  */ ComponentTag[];
export type ComponentTagsListApiArg = {
    /** Search by all supported text fields */
    search?: string;
};
export type ComponentTagsRetrieveApiResponse = /** status 200  */ ComponentTag;
export type ComponentTagsRetrieveApiArg = {
    /** A unique integer value identifying this Komponendi viide. */
    id: number;
};
export type DowntimeProblemTagsListApiResponse = /** status 200  */ DowntimeProblem[];
export type DowntimeProblemTagsListApiArg = {
    /** A search term. */
    search?: string;
};
export type DowntimeProblemTagsRetrieveApiResponse = /** status 200  */ DowntimeProblem;
export type DowntimeProblemTagsRetrieveApiArg = {
    /** A unique integer value identifying this Jupi probleemi kirjeldus. */
    id: number;
};
export type DowntimesListApiResponse = /** status 200  */ DowntimeList[];
export type DowntimesListApiArg = {
    workOrderId?: number;
    /** Multiple values may be separated by commas. */
    workOrderIdIn?: number[];
};
export type DowntimesCreateApiResponse = /** status 201  */ DowntimeCreate;
export type DowntimesCreateApiArg = {
    downtimeCreate: DowntimeCreate;
};
export type DowntimesSolvePartialUpdateApiResponse = /** status 200  */ DowntimeList;
export type DowntimesSolvePartialUpdateApiArg = {
    /** A unique integer value identifying this downtime. */
    id: number;
    patchedDowntimeList: PatchedDowntimeList;
};
export type HistoryRetrieveApiResponse = unknown;
export type HistoryRetrieveApiArg = {
    id: number;
};
export type MachineModeSwitchCreateApiResponse = /** status 201  */ MachineModeSwitchLog;
export type MachineModeSwitchCreateApiArg = {
    machineModeSwitchLog: MachineModeSwitchLog;
};
export type MeasurementsListApiResponse = /** status 200  */ PipeMeasurement[];
export type MeasurementsListApiArg = void;
export type MeasurementsCreateApiResponse = /** status 201  */ PipeMeasurement;
export type MeasurementsCreateApiArg = {
    pipeMeasurement: PipeMeasurement;
};
export type MeasurementsDataRetrieveApiResponse = unknown;
export type MeasurementsDataRetrieveApiArg = {
    id: number;
};
export type MeasurementsUpdateApiResponse = /** status 200  */ PipeMeasurement;
export type MeasurementsUpdateApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
    pipeMeasurement: PipeMeasurement;
};
export type MeasurementsPartialUpdateApiResponse = /** status 200  */ PipeMeasurement;
export type MeasurementsPartialUpdateApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
    patchedPipeMeasurement: PatchedPipeMeasurement;
};
export type OdooBillOfMaterialComponentArticlesListApiResponse = /** status 200  */ BillOfMaterialComponentArticles[];
export type OdooBillOfMaterialComponentArticlesListApiArg = {
    /** Filtreerige täpse kategooria ID järgi. */
    articleCategoryIdExact?: string;
    /** Filter by default_code being in provided list */
    defaultCodeIn?: string[];
    /** Filter by orphaned status. */
    orphaned?: boolean;
    /** Search by all supported text fields */
    search?: string;
};
export type OdooManufacturingOrderRetrieveApiResponse = /** status 200  */ ManufacturingOrder;
export type OdooManufacturingOrderRetrieveApiArg = void;
export type OdooManufacturingOrderCreateApiResponse = /** status 201  */ ManufacturingOrder;
export type OdooManufacturingOrderCreateApiArg = {
    manufacturingOrder: ManufacturingOrder;
};
export type OdooManufacturingOrderUpdateApiResponse = /** status 200  */ ManufacturingOrderUpdate;
export type OdooManufacturingOrderUpdateApiArg = {
    manufacturingOrderUpdate: ManufacturingOrderUpdate;
};
export type OdooManufacturingOrderDestroyApiResponse = unknown;
export type OdooManufacturingOrderDestroyApiArg = void;
export type OrdersAlwaysInSystemUpdateApiResponse = /** status 200  */ OrderAlwaysInTheSystemUpdateField;
export type OrdersAlwaysInSystemUpdateApiArg = {
    id: string;
    orderAlwaysInTheSystemUpdateField: OrderAlwaysInTheSystemUpdateField;
};
export type PartActionsListApiResponse = /** status 200  */ Action[];
export type PartActionsListApiArg = void;
export type PartActionsRetrieveApiResponse = /** status 200  */ Action;
export type PartActionsRetrieveApiArg = {
    /** A unique integer value identifying this Tegevus. */
    id: number;
};
export type PartBlockersListApiResponse = /** status 200  */ PartBlocker[];
export type PartBlockersListApiArg = void;
export type PartBlockersCreateApiResponse = /** status 201  */ PartBlocker;
export type PartBlockersCreateApiArg = {
    partBlocker: PartBlocker;
};
export type PartBlockersRetrieveApiResponse = /** status 200  */ PartBlocker;
export type PartBlockersRetrieveApiArg = {
    /** A unique integer value identifying this juppide blokker. */
    id: number;
};
export type PartBlockersUpdateApiResponse = /** status 200  */ PartBlocker;
export type PartBlockersUpdateApiArg = {
    /** A unique integer value identifying this juppide blokker. */
    id: number;
    partBlocker: PartBlocker;
};
export type PartBlockersPartialUpdateApiResponse = /** status 200  */ PartBlocker;
export type PartBlockersPartialUpdateApiArg = {
    /** A unique integer value identifying this juppide blokker. */
    id: number;
    patchedPartBlocker: PatchedPartBlocker;
};
export type PartBlockersDestroyApiResponse = unknown;
export type PartBlockersDestroyApiArg = {
    /** A unique integer value identifying this juppide blokker. */
    id: number;
};
export type PartBlockersBulkDeleteDestroyApiResponse = unknown;
export type PartBlockersBulkDeleteDestroyApiArg = void;
export type PartOrdersListApiResponse = /** status 200  */ PaginatedPipeOrderList;
export type PartOrdersListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
};
export type PartOrdersRetrieveApiResponse = /** status 200  */ PipeOrder;
export type PartOrdersRetrieveApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type PartProductsListApiResponse = /** status 200  */ PartProductList[];
export type PartProductsListApiArg = void;
export type PartProductsCreateApiResponse = /** status 201  */ PartProductList;
export type PartProductsCreateApiArg = {
    partProductList: PartProductList;
};
export type PartProductsRetrieveApiResponse = /** status 200  */ PartProductList;
export type PartProductsRetrieveApiArg = {
    /** A unique integer value identifying this part product. */
    id: number;
};
export type PartProductsUpdateApiResponse = /** status 200  */ PartProductList;
export type PartProductsUpdateApiArg = {
    /** A unique integer value identifying this part product. */
    id: number;
    partProductList: PartProductList;
};
export type PartProductsPartialUpdateApiResponse = /** status 200  */ PartProductList;
export type PartProductsPartialUpdateApiArg = {
    /** A unique integer value identifying this part product. */
    id: number;
    patchedPartProductList: PatchedPartProductList;
};
export type PartProductsDestroyApiResponse = unknown;
export type PartProductsDestroyApiArg = {
    /** A unique integer value identifying this part product. */
    id: number;
};
export type PartProductsActivateCreateApiResponse = /** status 200  */ PartProductList;
export type PartProductsActivateCreateApiArg = {
    partProductList: PartProductList;
};
export type PartProductsBulkUpdateUpdateApiResponse = /** status 200  */ PartProductList;
export type PartProductsBulkUpdateUpdateApiArg = {
    partProductList: PartProductList;
};
export type PartProductsBulkUpdatePartialUpdateApiResponse = /** status 200  */ PartProductList;
export type PartProductsBulkUpdatePartialUpdateApiArg = {
    patchedPartProductList: PatchedPartProductList;
};
export type PartProductsDeactivateCreateApiResponse = /** status 200  */ PartProductList;
export type PartProductsDeactivateCreateApiArg = {
    partProductList: PartProductList;
};
export type PartProductsOverlappingActionsRetrieveApiResponse = /** status 200  */ PartProductList;
export type PartProductsOverlappingActionsRetrieveApiArg = void;
export type PartProductsPlanCreateApiResponse = /** status 200  */ PartProductList;
export type PartProductsPlanCreateApiArg = {
    partProductList: PartProductList;
};
export type PartProductsRevokeCreateApiResponse = /** status 200  */ PartProductList;
export type PartProductsRevokeCreateApiArg = {
    partProductList: PartProductList;
};
export type PartProductsUnplanCreateApiResponse = /** status 200  */ PartProductList;
export type PartProductsUnplanCreateApiArg = {
    partProductList: PartProductList;
};
export type PartTemplatesListApiResponse = /** status 200  */ PartTemplate[];
export type PartTemplatesListApiArg = void;
export type PartTemplatesCreateApiResponse = /** status 201  */ PartTemplate;
export type PartTemplatesCreateApiArg = {
    partTemplate: PartTemplate;
};
export type PartTemplatesRetrieveApiResponse = /** status 200  */ PartTemplate;
export type PartTemplatesRetrieveApiArg = {
    /** A unique integer value identifying this Jupi mall. */
    id: number;
};
export type PartTemplatesUpdateApiResponse = /** status 200  */ PartTemplate;
export type PartTemplatesUpdateApiArg = {
    /** A unique integer value identifying this Jupi mall. */
    id: number;
    partTemplate: PartTemplate;
};
export type PartTemplatesPartialUpdateApiResponse = /** status 200  */ PartTemplate;
export type PartTemplatesPartialUpdateApiArg = {
    /** A unique integer value identifying this Jupi mall. */
    id: number;
    patchedPartTemplate: PatchedPartTemplate;
};
export type PartTemplatesDestroyApiResponse = unknown;
export type PartTemplatesDestroyApiArg = {
    /** A unique integer value identifying this Jupi mall. */
    id: number;
};
export type PartTimeplanListApiResponse = /** status 200  */ PaginatedPipeOrderList;
export type PartTimeplanListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
};
export type PartTimeplanRetrieveApiResponse = /** status 200  */ PipeOrder;
export type PartTimeplanRetrieveApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type PartTypesListApiResponse = /** status 200  */ PartType[];
export type PartTypesListApiArg = void;
export type PartTypesRetrieveApiResponse = /** status 200  */ PartType;
export type PartTypesRetrieveApiArg = {
    /** A unique integer value identifying this part type. */
    id: number;
};
export type PartWorkPlansRetrieveApiResponse = unknown;
export type PartWorkPlansRetrieveApiArg = void;
export type PipeBlockersListApiResponse = /** status 200  */ PipeBlocker[];
export type PipeBlockersListApiArg = void;
export type PipeBlockersCreateApiResponse = /** status 201  */ PipeBlocker;
export type PipeBlockersCreateApiArg = {
    pipeBlocker: PipeBlocker;
};
export type PipeBlockersRetrieveApiResponse = /** status 200  */ PipeBlocker;
export type PipeBlockersRetrieveApiArg = {
    /** A unique integer value identifying this torude blokker. */
    id: number;
};
export type PipeBlockersUpdateApiResponse = /** status 200  */ PipeBlocker;
export type PipeBlockersUpdateApiArg = {
    /** A unique integer value identifying this torude blokker. */
    id: number;
    pipeBlocker: PipeBlocker;
};
export type PipeBlockersPartialUpdateApiResponse = /** status 200  */ PipeBlocker;
export type PipeBlockersPartialUpdateApiArg = {
    /** A unique integer value identifying this torude blokker. */
    id: number;
    patchedPipeBlocker: PatchedPipeBlocker;
};
export type PipeBlockersDestroyApiResponse = unknown;
export type PipeBlockersDestroyApiArg = {
    /** A unique integer value identifying this torude blokker. */
    id: number;
};
export type PipeBlockersBulkDeleteDestroyApiResponse = unknown;
export type PipeBlockersBulkDeleteDestroyApiArg = void;
export type PipeFieldUpdateUpdateApiResponse = /** status 200  */ PipeUpdateField;
export type PipeFieldUpdateUpdateApiArg = {
    id: number;
    pipeUpdateField: PipeUpdateField;
};
export type PipeOrdersListApiResponse = /** status 200  */ PaginatedPipeOrderList;
export type PipeOrdersListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
};
export type PipeOrdersRetrieveApiResponse = /** status 200  */ PipeOrder;
export type PipeOrdersRetrieveApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type PipePauseProblemTagsListApiResponse = /** status 200  */ PipePauseProblemTag[];
export type PipePauseProblemTagsListApiArg = {
    /** A search term. */
    search?: string;
};
export type PipePauseProblemTagsRetrieveApiResponse = /** status 200  */ PipePauseProblemTag;
export type PipePauseProblemTagsRetrieveApiArg = {
    /** A unique integer value identifying this Toru pausi probleemi viide. */
    id: number;
};
export type PipeProductsListApiResponse = /** status 200  */ PipeProductList[];
export type PipeProductsListApiArg = void;
export type PipeProductsCreateApiResponse = /** status 201  */ PipeProductList;
export type PipeProductsCreateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeProductsRetrieveApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsRetrieveApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
};
export type PipeProductsUpdateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsUpdateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsPartialUpdateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsPartialUpdateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    patchedPipeProductList: PatchedPipeProductList;
};
export type PipeProductsDestroyApiResponse = unknown;
export type PipeProductsDestroyApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
};
export type PipeProductsCancelPredictionCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsCancelPredictionCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsConfigureCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsConfigureCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsEndCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsEndCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsFaultyStatsRetrieveApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsFaultyStatsRetrieveApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
};
export type PipeProductsMultiStageCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsMultiStageCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsPauseCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsPauseCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsPipeRetrieveApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsPipeRetrieveApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
};
export type PipeProductsSplitCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsSplitCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsStartCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsStartCreateApiArg = {
    /** A unique integer value identifying this Toru toode. */
    id: number;
    pipeProductList: PipeProductList;
};
export type PipeProductsActivateCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsActivateCreateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeProductsBulkUpdateUpdateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsBulkUpdateUpdateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeProductsBulkUpdatePartialUpdateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsBulkUpdatePartialUpdateApiArg = {
    patchedPipeProductList: PatchedPipeProductList;
};
export type PipeProductsDeactivateCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsDeactivateCreateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeProductsMergeCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsMergeCreateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeProductsPlanCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsPlanCreateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeProductsPlannedRetrieveApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsPlannedRetrieveApiArg = void;
export type PipeProductsUnplanCreateApiResponse = /** status 200  */ PipeProductList;
export type PipeProductsUnplanCreateApiArg = {
    pipeProductList: PipeProductList;
};
export type PipeTagsListApiResponse = /** status 200  */ Tag[];
export type PipeTagsListApiArg = {
    /** Search by all supported text fields */
    search?: string;
    showInListInitially?: boolean;
};
export type PipeTagsRetrieveApiResponse = /** status 200  */ Tag;
export type PipeTagsRetrieveApiArg = {
    /** A unique integer value identifying this Toru viide. */
    id: number;
};
export type PipeTimeplanListApiResponse = /** status 200  */ PaginatedPipeOrderList;
export type PipeTimeplanListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
};
export type PipeTimeplanPlaningModalListApiResponse = /** status 200  */ PaginatedPipeOrderList;
export type PipeTimeplanPlaningModalListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
};
export type PipeTimeplanPlaningModalRetrieveApiResponse = /** status 200  */ PipeOrder;
export type PipeTimeplanPlaningModalRetrieveApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type PipeTimeplanRetrieveApiResponse = /** status 200  */ PipeOrder;
export type PipeTimeplanRetrieveApiArg = {
    /** A unique integer value identifying this order. */
    id: number;
};
export type PipesListApiResponse = /** status 200  */ PaginatedPipeUpdateList;
export type PipesListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
};
export type PipesCreateApiResponse = /** status 201  */ PipeUpdate;
export type PipesCreateApiArg = {
    pipeUpdate: PipeUpdate;
};
export type PipesExportRetrieveApiResponse = unknown;
export type PipesExportRetrieveApiArg = void;
export type PipesExportCreateApiResponse = unknown;
export type PipesExportCreateApiArg = void;
export type PipesRetrieveApiResponse = /** status 200  */ PipeUpdate;
export type PipesRetrieveApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
};
export type PipesUpdateApiResponse = /** status 200  */ PipeUpdate;
export type PipesUpdateApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
    pipeUpdate: PipeUpdate;
};
export type PipesPartialUpdateApiResponse = /** status 200  */ PipeUpdate;
export type PipesPartialUpdateApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
    patchedPipeUpdate: PatchedPipeUpdate;
};
export type PipesDestroyApiResponse = unknown;
export type PipesDestroyApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
};
export type PipesOdooManualConfirmUpdateApiResponse = /** status 200  */ PipeUpdate;
export type PipesOdooManualConfirmUpdateApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
    pipeUpdate: PipeUpdate;
};
export type PipesProduceNewUpdateApiResponse = /** status 200  */ PipeUpdate;
export type PipesProduceNewUpdateApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
    pipeUpdate: PipeUpdate;
};
export type ReportsListApiResponse = /** status 200  */ PipeOverview[];
export type ReportsListApiArg = void;
export type ReportsColumnsRetrieveApiResponse = unknown;
export type ReportsColumnsRetrieveApiArg = {
    tab: string;
};
export type ReportsColumnsCreateApiResponse = unknown;
export type ReportsColumnsCreateApiArg = {
    tab: string;
};
export type ReportsRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsRetrieveApiArg = {
    /** A unique integer value identifying this Toru. */
    id: number;
};
export type ReportsCertifiedRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsCertifiedRetrieveApiArg = void;
export type ReportsFaultsRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsFaultsRetrieveApiArg = void;
export type ReportsMaterialPlanRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsMaterialPlanRetrieveApiArg = void;
export type ReportsMaterialsRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsMaterialsRetrieveApiArg = void;
export type ReportsOverviewRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsOverviewRetrieveApiArg = void;
export type ReportsTagGroupsRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsTagGroupsRetrieveApiArg = void;
export type ReportsTagsRetrieveApiResponse = /** status 200  */ PipeOverview;
export type ReportsTagsRetrieveApiArg = void;
export type StationsListApiResponse = /** status 200  */ Station[];
export type StationsListApiArg = {
    /** * `1` - Jahtumine
     * `2` - Mõõdistamine
     * `3` - Operatsioon */
    stationType?: 1 | 2 | 3;
    /** Multiple values may be separated by commas. */
    stationTypeIn?: number[];
};
export type StationsCreateApiResponse = /** status 201  */ Station;
export type StationsCreateApiArg = {
    station: Station;
};
export type StationsSubscriptionsListApiResponse = /** status 200  */ PaginatedStationSubscriptionSerializerListList;
export type StationsSubscriptionsListApiArg = {
    /** The pagination cursor value. */
    cursor?: string;
    endTime?: string;
    startTime?: string;
    stations?: number[];
    subscriberObjectId?: number;
    subscriberType?: number;
};
export type StationsSubscriptionsCreateApiResponse = /** status 201  */ StationSubscriptionSerializerList;
export type StationsSubscriptionsCreateApiArg = {
    stationSubscriptionSerializerList: StationSubscriptionSerializerList;
};
export type StationsSubscriptionsRetrieveApiResponse = /** status 200  */ StationSubscriptionSerializerDetail;
export type StationsSubscriptionsRetrieveApiArg = {
    /** A unique integer value identifying this subscription. */
    id: number;
};
export type StationsSubscriptionsUpdateApiResponse = /** status 200  */ StationSubscriptionSerializerList;
export type StationsSubscriptionsUpdateApiArg = {
    /** A unique integer value identifying this subscription. */
    id: number;
    stationSubscriptionSerializerList: StationSubscriptionSerializerList;
};
export type StationsSubscriptionsPartialUpdateApiResponse = /** status 200  */ StationSubscriptionSerializerList;
export type StationsSubscriptionsPartialUpdateApiArg = {
    /** A unique integer value identifying this subscription. */
    id: number;
    patchedStationSubscriptionSerializerList: PatchedStationSubscriptionSerializerList;
};
export type StationsSubscriptionsDestroyApiResponse = unknown;
export type StationsSubscriptionsDestroyApiArg = {
    /** A unique integer value identifying this subscription. */
    id: number;
};
export type StationsSubscriptionsChangeStationsCreateApiResponse = /** status 200  */ StationSubscriptionSerializerList;
export type StationsSubscriptionsChangeStationsCreateApiArg = {
    /** A unique integer value identifying this subscription. */
    id: number;
    stationSubscriptionSerializerList: StationSubscriptionSerializerList;
};
export type StationsSubscriptionsStopCreateApiResponse = /** status 200  */ StationSubscriptionSerializerList;
export type StationsSubscriptionsStopCreateApiArg = {
    /** A unique integer value identifying this subscription. */
    id: number;
    stationSubscriptionSerializerList: StationSubscriptionSerializerList;
};
export type StationsRetrieveApiResponse = /** status 200  */ Station;
export type StationsRetrieveApiArg = {
    /** A unique integer value identifying this Tööjaam. */
    id: number;
};
export type StationsUpdateApiResponse = /** status 200  */ Station;
export type StationsUpdateApiArg = {
    /** A unique integer value identifying this Tööjaam. */
    id: number;
    station: Station;
};
export type StationsPartialUpdateApiResponse = /** status 200  */ Station;
export type StationsPartialUpdateApiArg = {
    /** A unique integer value identifying this Tööjaam. */
    id: number;
    patchedStation: PatchedStation;
};
export type StationsDestroyApiResponse = unknown;
export type StationsDestroyApiArg = {
    /** A unique integer value identifying this Tööjaam. */
    id: number;
};
export type TimeTrackersListApiResponse = /** status 200  */ TimeTracker[];
export type TimeTrackersListApiArg = {
    workOrderId?: number;
    /** Multiple values may be separated by commas. */
    workOrderIdIn?: number[];
};
export type TimeTrackersCreateApiResponse = /** status 201  */ TimeTracker;
export type TimeTrackersCreateApiArg = {
    timeTracker: TimeTracker;
};
export type TimeTrackersRetrieveApiResponse = /** status 200  */ TimeTracker;
export type TimeTrackersRetrieveApiArg = {
    /** A unique integer value identifying this time tracker. */
    id: number;
};
export type TimeTrackersUpdateApiResponse = /** status 200  */ TimeTracker;
export type TimeTrackersUpdateApiArg = {
    /** A unique integer value identifying this time tracker. */
    id: number;
    timeTracker: TimeTracker;
};
export type TimeTrackersPartialUpdateApiResponse = /** status 200  */ TimeTracker;
export type TimeTrackersPartialUpdateApiArg = {
    /** A unique integer value identifying this time tracker. */
    id: number;
    patchedTimeTracker: PatchedTimeTracker;
};
export type TimeTrackersDestroyApiResponse = unknown;
export type TimeTrackersDestroyApiArg = {
    /** A unique integer value identifying this time tracker. */
    id: number;
};
export type TimeTrackersPausePartialUpdateApiResponse = /** status 200  */ TimeTracker;
export type TimeTrackersPausePartialUpdateApiArg = {
    /** A unique integer value identifying this time tracker. */
    id: number;
    patchedTimeTracker: PatchedTimeTracker;
};
export type TimeTrackersStopPartialUpdateApiResponse = /** status 200  */ TimeTracker;
export type TimeTrackersStopPartialUpdateApiArg = {
    /** A unique integer value identifying this time tracker. */
    id: number;
    patchedTimeTracker: PatchedTimeTracker;
};
export type WorkplanBodyExpansionTypesListApiResponse = /** status 200  */ BodyExpansionType[];
export type WorkplanBodyExpansionTypesListApiArg = void;
export type WorkplanBodyExpansionTypesRetrieveApiResponse = /** status 200  */ BodyExpansionType;
export type WorkplanBodyExpansionTypesRetrieveApiArg = {
    /** A unique integer value identifying this Keha pikenduse tüüp. */
    id: number;
};
export type WorkplanCuttingOptionsRetrieveApiResponse = unknown;
export type WorkplanCuttingOptionsRetrieveApiArg = void;
export type WorkplanFormulaCalculationRetrieveApiResponse = unknown;
export type WorkplanFormulaCalculationRetrieveApiArg = void;
export type WorkplanPartWorkorderActionsListApiResponse = /** status 200  */ WorkOrderAction[];
export type WorkplanPartWorkorderActionsListApiArg = void;
export type WorkplanPartWorkorderActionsCreateApiResponse = /** status 201  */ WorkOrderAction;
export type WorkplanPartWorkorderActionsCreateApiArg = {
    workOrderAction: WorkOrderAction;
};
export type WorkplanPartWorkorderActionsRetrieveApiResponse = /** status 200  */ WorkOrderAction;
export type WorkplanPartWorkorderActionsRetrieveApiArg = {
    /** A unique integer value identifying this Töölehe tegevus. */
    id: number;
};
export type WorkplanPartWorkorderActionsUpdateApiResponse = /** status 200  */ WorkOrderAction;
export type WorkplanPartWorkorderActionsUpdateApiArg = {
    /** A unique integer value identifying this Töölehe tegevus. */
    id: number;
    workOrderAction: WorkOrderAction;
};
export type WorkplanPartWorkorderActionsPartialUpdateApiResponse = /** status 200  */ WorkOrderAction;
export type WorkplanPartWorkorderActionsPartialUpdateApiArg = {
    /** A unique integer value identifying this Töölehe tegevus. */
    id: number;
    patchedWorkOrderAction: PatchedWorkOrderAction;
};
export type WorkplanPartWorkorderActionsDestroyApiResponse = unknown;
export type WorkplanPartWorkorderActionsDestroyApiArg = {
    /** A unique integer value identifying this Töölehe tegevus. */
    id: number;
};
export type WorkplanPartWorkorderActionsBulkUpdateUpdateApiResponse = /** status 200  */ WorkOrderAction;
export type WorkplanPartWorkorderActionsBulkUpdateUpdateApiArg = {
    workOrderAction: WorkOrderAction;
};
export type WorkplanPartWorkorderActionsBulkUpdatePartialUpdateApiResponse = /** status 200  */ WorkOrderAction;
export type WorkplanPartWorkorderActionsBulkUpdatePartialUpdateApiArg = {
    patchedWorkOrderAction: PatchedWorkOrderAction;
};
export type WorkplanPartWorkordersListApiResponse = /** status 200  */ WorkOrder[];
export type WorkplanPartWorkordersListApiArg = void;
export type WorkplanPartWorkordersRetrieveApiResponse = /** status 200  */ WorkOrder;
export type WorkplanPartWorkordersRetrieveApiArg = {
    /** A unique integer value identifying this Tööleht. */
    id: number;
};
export type MachineDataElikoMandrelActionCreateApiResponse = unknown;
export type MachineDataElikoMandrelActionCreateApiArg = void;
export type MachineDataElikoMandrelActionManyCreateApiResponse = unknown;
export type MachineDataElikoMandrelActionManyCreateApiArg = void;
export type MachineDataMandrelActionCreateApiResponse = unknown;
export type MachineDataMandrelActionCreateApiArg = void;
export type MachineDataMandrelActionManyCreateApiResponse = unknown;
export type MachineDataMandrelActionManyCreateApiArg = void;
export type MachineDataPipeProductionDataCreateApiResponse = unknown;
export type MachineDataPipeProductionDataCreateApiArg = void;
export type MachineDataPipeProductionDataManyCreateApiResponse = unknown;
export type MachineDataPipeProductionDataManyCreateApiArg = void;
export type OpenViewRetrieveApiResponse = unknown;
export type OpenViewRetrieveApiArg = void;
export type OpenViewCreateApiResponse = unknown;
export type OpenViewCreateApiArg = void;
export type Operator = {
    id: number;
    email: string;
    name: string;
    employee_number?: number | null;
    time_factor?: number;
    order: number;
};
export type Overtime = {
    id: number;
    worker: number;
    start_time: string;
    end_time: string;
};
export type PatchedOvertime = {
    id?: number;
    worker?: number;
    start_time?: string;
    end_time?: string;
};
export type ComponentTag = {
    name: string;
    id: number;
};
export type DowntimeProblem = {
    id: number;
    name: string;
};
export type DowntimeList = {
    id: number;
    work_order: number;
    is_solved?: boolean;
    problems: DowntimeProblem[];
    time_tracker: string;
};
export type DowntimeCreate = {
    id: number;
    work_order: number;
    is_solved?: boolean;
    problems?: number[];
    time_tracker: string;
};
export type PatchedDowntimeList = {
    id?: number;
    work_order?: number;
    is_solved?: boolean;
    problems?: DowntimeProblem[];
    time_tracker?: string;
};
export type OperationModeEnum = 'inactive' | 'auto' | 'manual' | 'scrap';
export type MachineModeSwitchLog = {
    /** a production machine unique Alphanumeric Value, e.G. P190924  */
    machine_id?: string;
    operation_mode: OperationModeEnum;
};
export type PipeMeasurement = {
    id: number;
    length_produced?: string | null;
    diameter: number;
    roundness_min_diameter?: string | null;
    roundness_max_diameter?: string | null;
    /** Measured weight of the pipe */
    weight?: string | null;
    spigot_diameter?: string | null;
    socket_diameter?: string | null;
    socket_thicknesses?: string[] | null;
    rib_caps?: string[] | null;
    outer_thicknesses?: string[] | null;
    outer_thicknesses_required: number;
    inner_thicknesses?: string[] | null;
    end_front: string;
    end_back: string;
    type: string;
    profile_height: number;
};
export type PatchedPipeMeasurement = {
    id?: number;
    length_produced?: string | null;
    diameter?: number;
    roundness_min_diameter?: string | null;
    roundness_max_diameter?: string | null;
    /** Measured weight of the pipe */
    weight?: string | null;
    spigot_diameter?: string | null;
    socket_diameter?: string | null;
    socket_thicknesses?: string[] | null;
    rib_caps?: string[] | null;
    outer_thicknesses?: string[] | null;
    outer_thicknesses_required?: number;
    inner_thicknesses?: string[] | null;
    end_front?: string;
    end_back?: string;
    type?: string;
    profile_height?: number;
};
export type BillOfMaterialComponentArticles = {
    /** Unique code for the article, used in odoo to identify the article. */
    default_code: string;
    article_category_id?: string;
    name: string;
};
export type MoTypeEnum = 'pipe' | 'part';
export type MoStatusEnum =
    | 'draft'
    | 'confirmed'
    | 'progress'
    | 'to_close'
    | 'done'
    | 'cancel';
export type QuantityUnitEnum = 'm' | 'pcs';
export type RealSourceEnum =
    | 'Extruder_1__MainComponent'
    | 'Extruder_2__MainComponent'
    | 'Extruder_1__AddComponent_1'
    | 'Extruder_2__AddComponent_1'
    | 'CoExtruder__MainComponent'
    | 'CoExtruder__AddComponent_1'
    | 'CoreTube__Feeder';
export type NullEnum = null;
export type BillOfMaterialComponentsExternal = {
    operation_id: number;
    /** The product code of the component, used to identify the component in odoo */
    product_code: string;
    quantity_planned: number;
    quantity_actual: number;
    quantity_actual_scrap: number;
    quantity_unit: string;
    /** Where we're getting actual quantity from, IE: which extruder from the production machine
    
    * `Extruder_1__MainComponent` - Extruder 1 - Main Component
    * `Extruder_2__MainComponent` - Extruder 2 - Main Component
    * `Extruder_1__AddComponent_1` - Extruder 1 - Lisa Komponent 1
    * `Extruder_2__AddComponent_1` - Extruder 2 - Lisa Komponent 1
    * `CoExtruder__MainComponent` - CoExtruder – Põhi Komponent
    * `CoExtruder__AddComponent_1` - CoExtruder - Lisa Komponent 1
    * `CoreTube__Feeder` - CoreTube - Feeder */
    real_source: (RealSourceEnum | NullEnum) | null;
    component_id: number;
};
export type ManufacturingOrder = {
    product_code: string;
    product_description?: string | null;
    mo_type: MoTypeEnum;
    mo_status?: MoStatusEnum;
    mo_reference: string;
    /** if not falsy will show up as a TT order in PQS */
    parent_mo_mo_reference: string | null;
    parent_mo_product_code?: string | null;
    parent_mo_product_description?: string | null;
    /** SaleOrderLine.id as integer from parent_mo, Can't be null for child MO */
    parent_mo_so_row_id?: number | null;
    /** Will be used as the order comment for TT orders in PQS, if blank will use parent_mo_product_description. In the form of '{SaleOrderLine.description} {SaleOrderLine.row_comment}' */
    parent_mo_so_row_comment?: string | null;
    /** From parent SaleOrderLine.description, by default filled with product name, but can be changed manually by user */
    parent_mo_so_row_description?: string | null;
    /** From parent SaleOrderLine.row_comment only, usually empty but users can fill it */
    parent_mo_so_row_comment_only?: string | null;
    parent_mo_mo_comment?: string | null;
    so_reference: string | null;
    so_comment?: string | null;
    /** SaleOrderLine.id as integer. Can be null for child MO */
    so_row_id?: number | null;
    /** Will be shown as the production description in PQS, if blank will show the product description. In the form of '{SaleOrderLine.description} {SaleOrderLine.row_comment}'. */
    so_row_comment?: string | null;
    /** SaleOrderLine.description, by default filled with product name, but can be changed manually by user */
    so_row_description?: string | null;
    /** SaleOrderLine.row_comment only, usually empty but users can fill it */
    so_row_comment_only?: string | null;
    /** Tootmistellimuse kommentaar */
    mo_comment?: string | null;
    mo_url?: string | null;
    mo_bom_url?: string | null;
    mo_product_url?: string | null;
    client_order_reference?: string | null;
    quantity: number;
    quantity_unit: QuantityUnitEnum;
    /** The quantity of the product to manufacture, does not include scrap pipe quantity */
    so_row_quantity?: number | null;
    /** The quantity of the product to manufacture, includes scrap pipe quantity */
    mo_total_quantity?: number | null;
    /** Date which the production manager expects the order to be finished */
    commitment_date?: string | null;
    components: BillOfMaterialComponentsExternal[];
    operations: string;
    client_id: number | null;
    client_name: string | null;
    part_total_duration_estimate: number;
};
export type ManufacturingOrderUpdate = {
    mo_reference: string;
    product_description?: string | null;
    /** if not falsy will show up as a TT order in PQS */
    parent_mo_mo_reference: string | null;
    parent_mo_product_code?: string | null;
    parent_mo_product_description?: string | null;
    /** SaleOrderLine.id as integer from parent_mo, Can't be null for child MO */
    parent_mo_so_row_id?: number | null;
    /** Will be used as the order comment for TT orders in PQS, if blank will use parent_mo_product_description. In the form of '{SaleOrderLine.description} {SaleOrderLine.row_comment}' */
    parent_mo_so_row_comment?: string | null;
    /** From parent SaleOrderLine.description, by default filled with product name, but can be changed manually by user */
    parent_mo_so_row_description?: string | null;
    /** From parent SaleOrderLine.row_comment only, usually empty but users can fill it */
    parent_mo_so_row_comment_only?: string | null;
    parent_mo_mo_comment?: string | null;
    so_comment?: string | null;
    /** SaleOrderLine.id as integer. Can be null for child MO */
    so_row_id?: number | null;
    /** Will be shown as the production description in PQS, if blank will show the product description. In the form of '{SaleOrderLine.description} {SaleOrderLine.row_comment}'. */
    so_row_comment?: string | null;
    /** SaleOrderLine.description, by default filled with product name, but can be changed manually by user */
    so_row_description?: string | null;
    /** SaleOrderLine.row_comment only, usually empty but users can fill it */
    so_row_comment_only?: string | null;
    mo_status?: MoStatusEnum;
    /** Quantity remaining in Odoo, tracked by Odoo, updated on update and confirm */
    mo_remaining_quantity?: number;
    /** Tootmistellimuse kommentaar */
    mo_comment?: string | null;
    /** Date which the production manager expects the order to be finished */
    commitment_date?: string | null;
    mo_url?: string | null;
    mo_bom_url?: string | null;
    mo_product_url?: string | null;
    /** The quantity of the product to manufacture, does not include scrap pipe quantity */
    so_row_quantity?: number | null;
    /** The quantity of the product to manufacture, includes scrap pipe quantity */
    mo_total_quantity?: number | null;
};
export type OrderAlwaysInTheSystemUpdateField = {
    always_show_in_system?: boolean;
};
export type WorkTypeEnum = 1 | 2 | 3;
export type Action = {
    id: number;
    name: string;
    part_type_id: number | null;
    /** Get a list of variables that have been used in the action's formula.
    
    Currently only returns used variables (OD_L, H_W_S, pieces, etc.) and not
    time constants defined in constance.
    
    Note: This actually parses the AST and it might be a bit inefficient
    (not sure about that). If there are some problems, then a simpler way is
    to just see if the formula string includes the variable names. However,
    that approach may find false-positives (e.g., when there is a variable
    defined `OD_L_test = 10` -- a simple string contains check would find
    that it includes `OD_L`). */
    used_variables: string[];
    order: number;
    formula: string;
    work_type: WorkTypeEnum;
    action_type: number;
    /** Enable if the action should not be listed as a valid choice in parts orders */
    archived: boolean;
};
export type PartBlocker = {
    id: number;
    name: string;
    start_minute?: number;
    duration?: number;
    period_start: string;
    period_end: string;
    weekdays?: number;
    operators: number[];
};
export type PatchedPartBlocker = {
    id?: number;
    name?: string;
    start_minute?: number;
    duration?: number;
    period_start?: string;
    period_end?: string;
    weekdays?: number;
    operators?: number[];
};
export type Client = {
    id: number;
    code: number;
    name: string;
};
export type PlanTypeEnum = 1 | 2;
export type InnerColor11BEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type BillOfMaterialComponentsInternal = {
    pk: number;
    quantity_planned_override: number;
    quantity_actual_override: number;
    quantity_actual_scrap_override: number;
    operation_id: number;
    /** The product code of the component, used to identify the component in odoo */
    product_code: string;
    quantity_planned: number;
    quantity_actual: number;
    quantity_actual_scrap: number;
    quantity_unit: string;
    /** Where we're getting actual quantity from, IE: which extruder from the production machine
    
    * `Extruder_1__MainComponent` - Extruder 1 - Main Component
    * `Extruder_2__MainComponent` - Extruder 2 - Main Component
    * `Extruder_1__AddComponent_1` - Extruder 1 - Lisa Komponent 1
    * `Extruder_2__AddComponent_1` - Extruder 2 - Lisa Komponent 1
    * `CoExtruder__MainComponent` - CoExtruder – Põhi Komponent
    * `CoExtruder__AddComponent_1` - CoExtruder - Lisa Komponent 1
    * `CoreTube__Feeder` - CoreTube - Feeder */
    real_source: (RealSourceEnum | NullEnum) | null;
    component_id: number;
};
export type PipeProduct = {
    id: number;
    /** Pipes are in mm, parts are always 1 pc */
    quantity: number;
    order_row: number;
    requires_attention?: boolean;
    start_time?: string | null;
    actual_start_time?: string | null;
    actual_end_time?: string | null;
    end_time?: string | null;
    duration_body?: number;
    is_polymark: string;
    is_unmarked_old_order: boolean;
    duration_misc?: number;
    duration_additional?: number;
    /** Predicted duration of the pipe production in minutes. */
    duration: number;
    production_status: number;
    status_overviews: {
        status_text: string;
        color: string | null;
        tooltip_text: string | null;
    }[];
    warning: string;
    profile: string;
    end_front?: number | null;
    end_back?: number | null;
    diameter: number;
    plan_type?: (PlanTypeEnum | NullEnum) | null;
    plan_value?: string;
    tools?: string;
    sn_class?: number | null;
    comment?: string;
    operation_comment?: string;
    profile_type: string;
    core_diameter: string;
    inertia: string;
    /** An active product will show up in the time plan and operations, when a order is canceled or reduced, products are deactivated to match the order quantity */
    active?: boolean;
    qrs_status: string;
    has_faults: string;
    inner_color: InnerColor11BEnum | null;
    is_part: string;
    is_internal: string;
    /** Pipe products which go through planning and operations steps, but do not go to QRS */
    is_maintenance?: boolean;
    /** Toru on roboti kasutusel */
    is_robot_pipe?: boolean;
    bill_of_material_components?: BillOfMaterialComponentsInternal[];
    /** The first stage of this multi-stage product */
    multi_stage_main_stage: number | null;
    /** Which stage this product is this multi-stage product */
    multi_stage_stage_order: number | null;
    /** Mitme-etapilise toru etappide arv */
    multi_stage_count: number | null;
    print_message?: string | null;
};
export type PipeOrderRow = {
    id: number;
    quantity: number;
    order: number;
    /** Comment from directo, used for special comment flags */
    comment?: string;
    description: string;
    unit: string;
    article: string;
    products: PipeProduct[];
};
export type OrderTypeEnum = 'DIRECTO' | 'ODOO';
export type PipeOrder = {
    id: number;
    client: Client;
    rows: PipeOrderRow[];
    /** What system was this order imported from, and what type of order.
    
    * `DIRECTO` - Directo
    * `ODOO` - Odoo müügitellimus */
    order_type: OrderTypeEnum;
    /** If this is a production order, this number is the number of the parent order */
    prod_order_number?: string;
    number: string;
    comment: string;
    last_modified: string;
    date: string;
    changed?: boolean;
    active?: boolean;
    always_show_in_system?: boolean;
    /** The parent order of this order, if this is production order */
    parent_order?: number | null;
};
export type PaginatedPipeOrderList = {
    next?: string | null;
    previous?: string | null;
    results: PipeOrder[];
};
export type ProductionStatusEnum = 0 | 1 | 2 | 3;
export type WorkOrderActionStatusEnum = 0 | 1 | 2;
export type WorkOrderAction = {
    id: number;
    action: Action;
    work_order: number;
    status?: WorkOrderActionStatusEnum;
    updated_at: string;
    additional_action_time?: number;
};
export type WorkOrder = {
    id: number;
    /** Planned time for when the work should start. */
    start_time: string;
    /** Planned time for when the work should be completed by. */
    end_time: string;
    /** Planned duration of the work order in minutes. */
    duration: number;
    part_product: number;
    worker: number;
    work_order_actions: WorkOrderAction[];
    status: number;
};
export type PartProductList = {
    id: number;
    order_row: number;
    production_status?: (ProductionStatusEnum | NullEnum) | null;
    /** An active product will show up in the time plan and operations, when a order is canceled or reduced, products are deactivated to match the order quantity */
    active?: boolean;
    is_part: string;
    work_plan_json?: any;
    work_plan_tag?: string | null;
    comment?: string;
    el_time: string;
    mt_time: string;
    keev_time: string;
    total_time: string;
    action_formula_errors: string;
    /** Parse the time per worker for the product.
    
    The reason this needs to be a separate method is that by default the
    Exception objects (which are returned by `time_per_worker`) aren't
    converted to JSON.
    
    It could also be a serializer, but I'm not really sure how that would
    look like. */
    time_per_worker: {
        [key: string]: {
            [key: string]: number | string[];
        };
    };
    /** Parse the times for unassigned actions. */
    unassigned_action_durations: {
        [key: string]: {
            [key: string]: number | string[];
        };
    };
    work_orders: WorkOrder[];
    client: string;
    order_number: string;
};
export type PatchedPartProductList = {
    id?: number;
    order_row?: number;
    production_status?: (ProductionStatusEnum | NullEnum) | null;
    /** An active product will show up in the time plan and operations, when a order is canceled or reduced, products are deactivated to match the order quantity */
    active?: boolean;
    is_part?: string;
    work_plan_json?: any;
    work_plan_tag?: string | null;
    comment?: string;
    el_time?: string;
    mt_time?: string;
    keev_time?: string;
    total_time?: string;
    action_formula_errors?: string;
    /** Parse the time per worker for the product.
    
    The reason this needs to be a separate method is that by default the
    Exception objects (which are returned by `time_per_worker`) aren't
    converted to JSON.
    
    It could also be a serializer, but I'm not really sure how that would
    look like. */
    time_per_worker?: {
        [key: string]: {
            [key: string]: number | string[];
        };
    };
    /** Parse the times for unassigned actions. */
    unassigned_action_durations?: {
        [key: string]: {
            [key: string]: number | string[];
        };
    };
    work_orders?: WorkOrder[];
    client?: string;
    order_number?: string;
};
export type PartTemplate = {
    id: number;
    part_type_name: string;
    name: string;
    created_at: string;
    default_params?: any;
    part_type?: number | null;
};
export type PatchedPartTemplate = {
    id?: number;
    part_type_name?: string;
    name?: string;
    created_at?: string;
    default_params?: any;
    part_type?: number | null;
};
export type ComponentTypeEnum = 1 | 2;
export type PartType = {
    id: number;
    name: string;
    component_type: ComponentTypeEnum;
    /** Viimati kasutatud tööplaan. */
    last_work_plan: any | null;
};
export type PipeBlocker = {
    id: number;
    name: string;
    start_minute?: number;
    duration?: number;
    period_start: string;
    period_end: string;
    weekdays?: number;
};
export type PatchedPipeBlocker = {
    id?: number;
    name?: string;
    start_minute?: number;
    duration?: number;
    period_start?: string;
    period_end?: string;
    weekdays?: number;
};
export type PipeUpdateField = {
    grinder: number;
    /** Extra information about the pause reasons */
    pause_reason_extra_info?: string | null;
};
export type PipePauseProblemTag = {
    id: number;
    name: string;
    /** Kuva see viide saadaval olevate viidete loendis, kui toru on peatatud */
    active: boolean;
    created: string;
    modified: string;
    /** Kui probleemi pole loendis */
    other: boolean;
};
export type PipeProductList = {
    id: number;
    /** Pipes are in mm, parts are always 1 pc */
    quantity: number;
    order_row: number;
    requires_attention?: boolean;
    start_time?: string | null;
    actual_start_time?: string | null;
    actual_end_time?: string | null;
    end_time?: string | null;
    duration_body?: number;
    is_polymark: string;
    is_unmarked_old_order: boolean;
    duration_misc?: number;
    duration_additional?: number;
    /** Predicted duration of the pipe production in minutes. */
    duration: number;
    production_status: number;
    status_overviews: {
        status_text: string;
        color: string | null;
        tooltip_text: string | null;
    }[];
    warning: string;
    profile: string;
    end_front?: number | null;
    end_back?: number | null;
    diameter: number;
    plan_type?: (PlanTypeEnum | NullEnum) | null;
    plan_value?: string;
    tools?: string;
    sn_class?: number | null;
    comment?: string;
    operation_comment?: string;
    profile_type: string;
    core_diameter: string;
    inertia: string;
    /** An active product will show up in the time plan and operations, when a order is canceled or reduced, products are deactivated to match the order quantity */
    active?: boolean;
    qrs_status: string;
    has_faults: string;
    inner_color: InnerColor11BEnum | null;
    is_part: string;
    is_internal: string;
    /** Pipe products which go through planning and operations steps, but do not go to QRS */
    is_maintenance?: boolean;
    /** Toru on roboti kasutusel */
    is_robot_pipe?: boolean;
    bill_of_material_components: BillOfMaterialComponentsInternal[];
    /** The first stage of this multi-stage product */
    multi_stage_main_stage: number | null;
    /** Which stage this product is this multi-stage product */
    multi_stage_stage_order: number | null;
    /** Mitme-etapilise toru etappide arv */
    multi_stage_count: number | null;
    print_message?: string | null;
    client: string;
    order_number: string;
};
export type PatchedPipeProductList = {
    id?: number;
    /** Pipes are in mm, parts are always 1 pc */
    quantity?: number;
    order_row?: number;
    requires_attention?: boolean;
    start_time?: string | null;
    actual_start_time?: string | null;
    actual_end_time?: string | null;
    end_time?: string | null;
    duration_body?: number;
    is_polymark?: string;
    is_unmarked_old_order?: boolean;
    duration_misc?: number;
    duration_additional?: number;
    /** Predicted duration of the pipe production in minutes. */
    duration?: number;
    production_status?: number;
    status_overviews?: {
        status_text: string;
        color: string | null;
        tooltip_text: string | null;
    }[];
    warning?: string;
    profile?: string;
    end_front?: number | null;
    end_back?: number | null;
    diameter?: number;
    plan_type?: (PlanTypeEnum | NullEnum) | null;
    plan_value?: string;
    tools?: string;
    sn_class?: number | null;
    comment?: string;
    operation_comment?: string;
    profile_type?: string;
    core_diameter?: string;
    inertia?: string;
    /** An active product will show up in the time plan and operations, when a order is canceled or reduced, products are deactivated to match the order quantity */
    active?: boolean;
    qrs_status?: string;
    has_faults?: string;
    inner_color?: InnerColor11BEnum | null;
    is_part?: string;
    is_internal?: string;
    /** Pipe products which go through planning and operations steps, but do not go to QRS */
    is_maintenance?: boolean;
    /** Toru on roboti kasutusel */
    is_robot_pipe?: boolean;
    bill_of_material_components?: BillOfMaterialComponentsInternal[];
    /** The first stage of this multi-stage product */
    multi_stage_main_stage?: number | null;
    /** Which stage this product is this multi-stage product */
    multi_stage_stage_order?: number | null;
    /** Mitme-etapilise toru etappide arv */
    multi_stage_count?: number | null;
    print_message?: string | null;
    client?: string;
    order_number?: string;
};
export type Tag = {
    name: string;
};
export type PipeUpdateInnerColorEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type PipeUpdate = {
    operator: number;
    robot_operator: number | null;
    inner_color?: PipeUpdateInnerColorEnum;
    pe_rc: string;
    length_produced?: string | null;
    roundness_min_diameter?: string | null;
    roundness_max_diameter?: string | null;
    /** Measured weight of the pipe */
    weight?: string | null;
    spigot_diameter?: string | null;
    socket_diameter?: string | null;
    socket_thicknesses?: string[] | null;
    rib_caps?: string[] | null;
    outer_thicknesses?: string[] | null;
    inner_thicknesses?: string[] | null;
    grinder?: number | null;
    finisher?: number | null;
    welding_installer?: number | null;
    measurer?: number | null;
    tags: Tag[];
    last_modified?: string;
    measuring_time?: string | null;
    pause_reason_tags: number[];
};
export type PaginatedPipeUpdateList = {
    next?: string | null;
    previous?: string | null;
    results: PipeUpdate[];
};
export type PatchedPipeUpdate = {
    operator?: number;
    robot_operator?: number | null;
    inner_color?: PipeUpdateInnerColorEnum;
    pe_rc?: string;
    length_produced?: string | null;
    roundness_min_diameter?: string | null;
    roundness_max_diameter?: string | null;
    /** Measured weight of the pipe */
    weight?: string | null;
    spigot_diameter?: string | null;
    socket_diameter?: string | null;
    socket_thicknesses?: string[] | null;
    rib_caps?: string[] | null;
    outer_thicknesses?: string[] | null;
    inner_thicknesses?: string[] | null;
    grinder?: number | null;
    finisher?: number | null;
    welding_installer?: number | null;
    measurer?: number | null;
    tags?: Tag[];
    last_modified?: string;
    measuring_time?: string | null;
    pause_reason_tags?: number[];
};
export type PipeOverviewStatusEnum = 0 | 1 | 2 | 3 | 4 | 5;
export type PipeOverview = {
    diameter: number;
    length_produced: number;
    operator_name: string;
    operator_number: number;
    grinder_name: string;
    grinder_number: number;
    finisher_name: string;
    finisher_number: number;
    welding_installer_name: string;
    welding_installer_number: number;
    measurer_name: string;
    measurer_number: number;
    tags?: number[];
    status?: PipeOverviewStatusEnum;
    weight: number;
    weight_suggested: number;
    is_test: string;
    is_machine: string;
    is_polymark: boolean;
    profile: string;
    workers: string;
};
export type StationTypeEnum = 1 | 2 | 3;
export type Station = {
    id: number;
    occupied: boolean;
    station_type?: StationTypeEnum;
    name: string;
};
export type StationSubscriptionSerializerList = {
    id: number;
    stations: string;
    subscriber: string;
};
export type PaginatedStationSubscriptionSerializerListList = {
    next?: string | null;
    previous?: string | null;
    results: StationSubscriptionSerializerList[];
};
export type StationSubscriptionSerializerDetail = {
    id: number;
    start_time?: string;
    end_time?: string | null;
    stations: string;
    previous: string;
};
export type PatchedStationSubscriptionSerializerList = {
    id?: number;
    stations?: string;
    subscriber?: string;
};
export type PatchedStation = {
    id?: number;
    occupied?: boolean;
    station_type?: StationTypeEnum;
    name?: string;
};
export type TimeTracker = {
    id: number;
    start_time: string;
    end_time: string;
    work_order: number;
};
export type PatchedTimeTracker = {
    id?: number;
    start_time?: string;
    end_time?: string;
    work_order?: number;
};
export type BodyExpansionType = {
    id: number;
    name: string;
    preparation_formula: string;
    welding_formula: string;
};
export type PatchedWorkOrderAction = {
    id?: number;
    action?: Action;
    work_order?: number;
    status?: WorkOrderActionStatusEnum;
    updated_at?: string;
    additional_action_time?: number;
};
export const {
    useAccountsOperatorsListQuery,
    useAccountsOperatorsRetrieveQuery,
    useAccountsOvertimesListQuery,
    useAccountsOvertimesCreateMutation,
    useAccountsOvertimesRetrieveQuery,
    useAccountsOvertimesUpdateMutation,
    useAccountsOvertimesPartialUpdateMutation,
    useAccountsOvertimesDestroyMutation,
    useComponentTagsListQuery,
    useComponentTagsRetrieveQuery,
    useDowntimeProblemTagsListQuery,
    useDowntimeProblemTagsRetrieveQuery,
    useDowntimesListQuery,
    useDowntimesCreateMutation,
    useDowntimesSolvePartialUpdateMutation,
    useHistoryRetrieveQuery,
    useMachineModeSwitchCreateMutation,
    useMeasurementsListQuery,
    useMeasurementsCreateMutation,
    useMeasurementsDataRetrieveQuery,
    useMeasurementsUpdateMutation,
    useMeasurementsPartialUpdateMutation,
    useOdooBillOfMaterialComponentArticlesListQuery,
    useOdooManufacturingOrderRetrieveQuery,
    useOdooManufacturingOrderCreateMutation,
    useOdooManufacturingOrderUpdateMutation,
    useOdooManufacturingOrderDestroyMutation,
    useOrdersAlwaysInSystemUpdateMutation,
    usePartActionsListQuery,
    usePartActionsRetrieveQuery,
    usePartBlockersListQuery,
    usePartBlockersCreateMutation,
    usePartBlockersRetrieveQuery,
    usePartBlockersUpdateMutation,
    usePartBlockersPartialUpdateMutation,
    usePartBlockersDestroyMutation,
    usePartBlockersBulkDeleteDestroyMutation,
    usePartOrdersListQuery,
    usePartOrdersRetrieveQuery,
    usePartProductsListQuery,
    usePartProductsCreateMutation,
    usePartProductsRetrieveQuery,
    usePartProductsUpdateMutation,
    usePartProductsPartialUpdateMutation,
    usePartProductsDestroyMutation,
    usePartProductsActivateCreateMutation,
    usePartProductsBulkUpdateUpdateMutation,
    usePartProductsBulkUpdatePartialUpdateMutation,
    usePartProductsDeactivateCreateMutation,
    usePartProductsOverlappingActionsRetrieveQuery,
    usePartProductsPlanCreateMutation,
    usePartProductsRevokeCreateMutation,
    usePartProductsUnplanCreateMutation,
    usePartTemplatesListQuery,
    usePartTemplatesCreateMutation,
    usePartTemplatesRetrieveQuery,
    usePartTemplatesUpdateMutation,
    usePartTemplatesPartialUpdateMutation,
    usePartTemplatesDestroyMutation,
    usePartTimeplanListQuery,
    usePartTimeplanRetrieveQuery,
    usePartTypesListQuery,
    usePartTypesRetrieveQuery,
    usePartWorkPlansRetrieveQuery,
    usePipeBlockersListQuery,
    usePipeBlockersCreateMutation,
    usePipeBlockersRetrieveQuery,
    usePipeBlockersUpdateMutation,
    usePipeBlockersPartialUpdateMutation,
    usePipeBlockersDestroyMutation,
    usePipeBlockersBulkDeleteDestroyMutation,
    usePipeFieldUpdateUpdateMutation,
    usePipeOrdersListQuery,
    usePipeOrdersRetrieveQuery,
    usePipePauseProblemTagsListQuery,
    usePipePauseProblemTagsRetrieveQuery,
    usePipeProductsListQuery,
    usePipeProductsCreateMutation,
    usePipeProductsRetrieveQuery,
    usePipeProductsUpdateMutation,
    usePipeProductsPartialUpdateMutation,
    usePipeProductsDestroyMutation,
    usePipeProductsCancelPredictionCreateMutation,
    usePipeProductsConfigureCreateMutation,
    usePipeProductsEndCreateMutation,
    usePipeProductsFaultyStatsRetrieveQuery,
    usePipeProductsMultiStageCreateMutation,
    usePipeProductsPauseCreateMutation,
    usePipeProductsPipeRetrieveQuery,
    usePipeProductsSplitCreateMutation,
    usePipeProductsStartCreateMutation,
    usePipeProductsActivateCreateMutation,
    usePipeProductsBulkUpdateUpdateMutation,
    usePipeProductsBulkUpdatePartialUpdateMutation,
    usePipeProductsDeactivateCreateMutation,
    usePipeProductsMergeCreateMutation,
    usePipeProductsPlanCreateMutation,
    usePipeProductsPlannedRetrieveQuery,
    usePipeProductsUnplanCreateMutation,
    usePipeTagsListQuery,
    usePipeTagsRetrieveQuery,
    usePipeTimeplanListQuery,
    usePipeTimeplanPlaningModalListQuery,
    usePipeTimeplanPlaningModalRetrieveQuery,
    usePipeTimeplanRetrieveQuery,
    usePipesListQuery,
    usePipesCreateMutation,
    usePipesExportRetrieveQuery,
    usePipesExportCreateMutation,
    usePipesRetrieveQuery,
    usePipesUpdateMutation,
    usePipesPartialUpdateMutation,
    usePipesDestroyMutation,
    usePipesOdooManualConfirmUpdateMutation,
    usePipesProduceNewUpdateMutation,
    useReportsListQuery,
    useReportsColumnsRetrieveQuery,
    useReportsColumnsCreateMutation,
    useReportsRetrieveQuery,
    useReportsCertifiedRetrieveQuery,
    useReportsFaultsRetrieveQuery,
    useReportsMaterialPlanRetrieveQuery,
    useReportsMaterialsRetrieveQuery,
    useReportsOverviewRetrieveQuery,
    useReportsTagGroupsRetrieveQuery,
    useReportsTagsRetrieveQuery,
    util,
    useStationsListQuery,
    useStationsCreateMutation,
    useStationsSubscriptionsListQuery,
    useStationsSubscriptionsCreateMutation,
    useStationsSubscriptionsRetrieveQuery,
    useStationsSubscriptionsUpdateMutation,
    useStationsSubscriptionsPartialUpdateMutation,
    useStationsSubscriptionsDestroyMutation,
    useStationsSubscriptionsChangeStationsCreateMutation,
    useStationsSubscriptionsStopCreateMutation,
    useStationsRetrieveQuery,
    useStationsUpdateMutation,
    useStationsPartialUpdateMutation,
    useStationsDestroyMutation,
    useTimeTrackersListQuery,
    useTimeTrackersCreateMutation,
    useTimeTrackersRetrieveQuery,
    useTimeTrackersUpdateMutation,
    useTimeTrackersPartialUpdateMutation,
    useTimeTrackersDestroyMutation,
    useTimeTrackersPausePartialUpdateMutation,
    useTimeTrackersStopPartialUpdateMutation,
    useWorkplanBodyExpansionTypesListQuery,
    useWorkplanBodyExpansionTypesRetrieveQuery,
    useWorkplanCuttingOptionsRetrieveQuery,
    useWorkplanFormulaCalculationRetrieveQuery,
    useWorkplanPartWorkorderActionsListQuery,
    useWorkplanPartWorkorderActionsCreateMutation,
    useWorkplanPartWorkorderActionsRetrieveQuery,
    useWorkplanPartWorkorderActionsUpdateMutation,
    useWorkplanPartWorkorderActionsPartialUpdateMutation,
    useWorkplanPartWorkorderActionsDestroyMutation,
    useWorkplanPartWorkorderActionsBulkUpdateUpdateMutation,
    useWorkplanPartWorkorderActionsBulkUpdatePartialUpdateMutation,
    useWorkplanPartWorkordersListQuery,
    useWorkplanPartWorkordersRetrieveQuery,
    useMachineDataElikoMandrelActionCreateMutation,
    useMachineDataElikoMandrelActionManyCreateMutation,
    useMachineDataMandrelActionCreateMutation,
    useMachineDataMandrelActionManyCreateMutation,
    useMachineDataPipeProductionDataCreateMutation,
    useMachineDataPipeProductionDataManyCreateMutation,
    useOpenViewRetrieveQuery,
    useOpenViewCreateMutation,
} = injectedRtkApi;
