import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fa/lib/Icon';
import classnames from 'classnames';

import TooltipOverlay from 'core/components/TooltipOverlay';

import styles from './PartProduct.scss';

const PartProductComment = ({ children = '', wrap = false }) => (
    <div className={styles.partProductCommentContainer}>
        <Icon name="comment" />
        {wrap ? (
            <div className={styles.commentText}>{children}</div>
        ) : (
            <TooltipOverlay
                tooltipKey="top"
                tooltipPlacement="top"
                tooltipText={children}
            >
                <div
                    className={classnames(
                        styles.commentText,
                        styles.commentOverflow,
                    )}
                >
                    {children}
                </div>
            </TooltipOverlay>
        )}
    </div>
);

PartProductComment.propTypes = {
    children: PropTypes.string,
    wrap: PropTypes.bool,
};

export default PartProductComment;
