import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import classNames from 'classnames';

import { getTranslation } from 'core/utils/translations';
import locale from 'core/utils/dateLocalization';
import { toggleFilterDisable } from 'core/ducks/listFilters';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { BsTrash } from 'react-icons/bs';
import dateFilterRanges from '../../utils/dateFilterRanges';

class FilterInput extends Component {
    static propTypes = {
        fieldKey: PropTypes.string,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        toggleFilter: PropTypes.func,
        initial: PropTypes.object,
        machineOperationMode: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const initialKey = props.initial ? Object.keys(props.initial)[0] : null;
        const initialValues = props.initial ? props.initial[initialKey] : null;

        const filters = Object.keys(DJ_CONST.FILTERS);
        const first = initialKey || filters[0];
        const filter = DJ_CONST.FILTERS[first];
        const inputChoices = filter.choices;
        const initialChoice = inputChoices ? inputChoices[0][0] : null;

        let defaultOperator = -1;
        if (filter.operators.length === 1) {
            defaultOperator = filter.operators[0];
        }
        // These values don't automatically end up in the
        // filter querySet as FilterValues. Add them manually
        // to the self.packFilter method.
        this.state = {
            filters,
            isDisabled: initialValues?.disabled,
            autoFilter: initialValues?.auto_filter,
            fieldName: first,
            fieldOperator: initialValues
                ? initialValues.operator
                : defaultOperator,
            fieldType: filter.type,
            choices: inputChoices,
            inputOne: initialValues ? initialValues.value : initialChoice,
            inputTwo: initialChoice,
        };

        this.update = debounce(
            () => this.props.onChange(this.packFilter()),
            400,
        );
    }

    getChoices(fieldName, fieldOperator) {
        const { choices } = DJ_CONST.FILTERS[fieldName];
        const specialMap = DJ_CONST.FILTERS[fieldName].special;
        const specialInputChoices = specialMap
            ? specialMap[fieldOperator] || specialMap.default
            : null;
        return choices || specialInputChoices;
    }

    getFieldDisplayName(fieldName) {
        const { label } = DJ_CONST.FILTERS[fieldName];
        if (label) {
            return label;
        }
        const fieldNameSplit = fieldName.split('__');
        return getTranslation(fieldNameSplit[fieldNameSplit.length - 1]);
    }

    fieldNameOptions() {
        return this.state.filters.map((key) => (
            <option key={key} value={key}>
                {this.getFieldDisplayName(key)}
            </option>
        ));
    }

    fieldOperatorOptions() {
        const ops = DJ_CONST.FILTERS[this.state.fieldName].operators;
        return ops.map((key) => (
            <option key={key} value={key}>
                {getTranslation(key)}
            </option>
        ));
    }

    filterInput({ readOnly }) {
        const noInputFilters = [
            'exists',
            'missing',
            'less_than_allowed',
            'greater_than_allowed',
            'inside_allowed',
            'outside_allowed',
            'true',
            'false',
            'all_pp_rc',
            'all_pe_rc',
            // odoo_is_failing_to_confirm
            'is_failing',
            'not_failing_anymore',
            'never_failed',
        ];

        if (
            this.state.fieldOperator === -1 ||
            noInputFilters.includes(this.state.fieldOperator)
        ) {
            return null;
        }

        const inputHelper = (key) => {
            if (this.state.choices) {
                const multi = this.state.fieldType === 'multi-select';
                return (
                    <Select
                        key={key}
                        disabled={readOnly}
                        value={this.state[key]}
                        className="search-input search-select"
                        onChange={(event) =>
                            multi
                                ? this.handleMultiSelectChange(key, event)
                                : this.handleSelectChange(key, event)
                        }
                        options={this.state.choices.map(([value, label]) => ({
                            value,
                            label,
                        }))}
                        multi={multi}
                        clearable={false}
                    />
                );
            } else if (this.state.fieldType === 'date') {
                const value =
                    this.state[key] !== '' ? moment(this.state[key]) : null;
                let label = gettext('All dates');
                if (value !== null) {
                    label = value.format('DD.MM.YYYY');
                }
                return (
                    <div
                        className={classNames('date-range-picker-wrapper', {
                            disabled: readOnly,
                        })}
                    >
                        <DateRangePicker
                            singleDatePicker
                            locale={locale}
                            onApply={(evt, picker) =>
                                this.handleDateChange(key, picker)
                            }
                        >
                            <span className="selected-date-range-btn">
                                {label}
                            </span>
                        </DateRangePicker>
                    </div>
                );
            } else if (this.state.fieldType === 'date-range') {
                let label = gettext('All dates');
                const currentStateValue = this.state[key];
                if (currentStateValue !== '' && currentStateValue !== null) {
                    const value = [
                        moment(currentStateValue[0]).format('DD.MM.YYYY'),
                        moment(currentStateValue[1]).format('DD.MM.YYYY'),
                    ];
                    label = `${value[0]} - ${value[1]}`;
                }

                return (
                    <div
                        className={classNames('date-range-picker-wrapper', {
                            disabled: readOnly,
                        })}
                    >
                        <DateRangePicker
                            ranges={dateFilterRanges()}
                            locale={locale}
                            onApply={(evt, picker) =>
                                this.handleDateRangeChange(key, picker)
                            }
                        >
                            <span className="selected-date-range-btn">
                                {label}
                            </span>
                        </DateRangePicker>
                    </div>
                );
            } else {
                return (
                    <input
                        disabled={readOnly}
                        className="search-input"
                        value={this.state[key]}
                        type={
                            this.state.fieldType
                                ? this.state.fieldType
                                : 'number'
                        }
                        onChange={(event) => this.handleInputChange(key, event)}
                    />
                );
            }
        };

        return (
            <span>
                {inputHelper('inputOne')}
                {this.state.fieldOperator === 'in_range'
                    ? inputHelper('inputTwo')
                    : null}
            </span>
        );
    }

    handleFieldNameChange(event) {
        const fieldName = event.target.value;
        const filter = DJ_CONST.FILTERS[fieldName];
        const fieldType = filter.type;
        const multi = filter.type === 'multi-select';

        let inputChoices = filter.choices;
        inputChoices =
            inputChoices ||
            this.getChoices(fieldName, this.state.fieldOperator);

        // Decision was to not preselect initial value for multiselect
        let inputOne = '';
        let inputTwo = '';
        if (!multi) {
            inputOne = inputChoices ? inputChoices[0][0] : '';
            inputTwo = inputOne;
        }

        let fieldOperator = -1;

        if (inputChoices && inputChoices === this.state.choices) {
            ({ inputOne } = this.state);
            ({ inputTwo } = this.state);
            ({ fieldOperator } = this.state.fieldOperator);
        } else if (filter.operators.length === 1) {
            fieldOperator = filter.operators[0];
        }

        if (multi) {
            inputOne = [inputOne];
        }

        this.setState(
            {
                fieldName,
                inputOne,
                inputTwo,
                isDisabled: false,
                fieldOperator,
                choices: inputChoices,
                fieldType,
            },
            fieldOperator !== -1 ? this.update : null,
        );
    }

    handleFieldOperatorChange(event) {
        const { fieldName, fieldType, inputOne } = this.state;
        const multi = fieldType === 'multi-select';
        const fieldOperator =
            parseInt(event.target.value, 10) || event.target.value;
        const choices =
            fieldOperator !== -1
                ? this.getChoices(fieldName, fieldOperator)
                : null;

        const shouldKeepOriginalValue = () => {
            if (!choices) {
                return true; // Keep the original value if there are no choices.
            }

            const choicesValuesArray = choices.map(
                ([choiceValue]) => choiceValue,
            );
            const originalValuesArray = Array.isArray(inputOne)
                ? inputOne
                : [inputOne];

            return originalValuesArray.every((value) =>
                choicesValuesArray.includes(value),
            );
        };

        let newInputOne;
        if (shouldKeepOriginalValue()) {
            newInputOne = inputOne;
        } else {
            newInputOne = multi ? [] : choices[0][0];
        }

        const updatedState = {
            fieldOperator,
            choices,
            inputOne: newInputOne,
        };

        this.setState(updatedState, this.update);
    }

    handleInputChange(name, event) {
        const { value } = event.target;
        this.setState({ [name]: value }, this.update);
    }

    handleSelectChange(name, event) {
        const { value } = event;
        this.setState({ [name]: value }, this.update);
    }

    handleMultiSelectChange(name, selectedOptions) {
        this.setState(
            { [name]: selectedOptions.map((o) => o.value) },
            this.update,
        );
    }

    handleDateChange(name, picker) {
        const value = picker.startDate.format('YYYY-MM-DD');
        this.setState({ [name]: value }, this.update);
    }

    handleDateRangeChange(name, picker) {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');
        this.setState({ [name]: [startDate, endDate] }, this.update);
    }

    packFilter() {
        let value = this.state.inputOne;
        if (this.state.fieldOperator === 'in_range') {
            value = [this.state.inputOne, this.state.inputTwo];
        }
        if (this.state.fieldType === 'date') {
            value = value !== '' ? value : null;
        }
        return {
            [this.state.fieldName]: [
                {
                    operator: this.state.fieldOperator,
                    value,
                    auto_filter: this.state.autoFilter,
                    disabled: this.state.isDisabled,
                },
            ],
        };
    }

    render() {
        const auto_filter =
            this.props.machineOperationMode === 'auto' && this.state.autoFilter;
        const readOnly = this.state.isDisabled;
        return (
            <div className="flex flex-wrap">
                <div className="flex flex-wrap">
                    <select
                        disabled={readOnly}
                        className="search-input"
                        onChange={(event) => this.handleFieldNameChange(event)}
                        value={this.state.fieldName}
                    >
                        {this.fieldNameOptions()}
                    </select>

                    <select
                        disabled={readOnly}
                        className="search-input"
                        onChange={(event) =>
                            this.handleFieldOperatorChange(event)
                        }
                        value={this.state.fieldOperator}
                    >
                        <option value={-1}>----</option>
                        {this.fieldOperatorOptions()}
                    </select>

                    {this.filterInput({ readOnly })}
                </div>

                <label className="control-label flex flex-col mb-0 mt-[-2px] mr-[10px] items-center ">
                    <span>{gettext('Filter')}</span>
                    <Switch
                        name={`disable-${this.props.fieldKey}`}
                        height={24}
                        width={48}
                        handleDiameter={20}
                        onChange={() => {
                            this.setState(
                                { isDisabled: !this.state.isDisabled },
                                this.update,
                            );
                            this.props.toggleFilter(
                                this.state.fieldName,
                                this.state.fieldOperator,
                                this.state.inputOne &&
                                    this.state.inputTwo &&
                                    this.state.inputOne !== this.state.inputTwo
                                    ? [this.state.inputOne, this.state.inputTwo]
                                    : this.state.inputOne,
                            );
                        }}
                        checked={!this.state.isDisabled}
                    />
                </label>
                {this.state.autoFilter ? null : (
                    <button
                        aria-label={gettext('Remove filter')}
                        type="button"
                        className="remove-button"
                        onClick={this.props.onDelete}
                    >
                        <BsTrash />
                    </button>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleFilter: (fieldName, operator, value) => {
        dispatch(toggleFilterDisable(fieldName, operator, value));
    },
});

export default connect(null, mapDispatchToProps)(FilterInput);
