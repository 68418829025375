import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import Modal from 'tg-modal';
import { Button } from 'react-bootstrap';
import { useDJConst } from './DJConstContext';
import { gettext, interpolate } from '../utils/text';

export const AutoLogout: React.FC<{
    promptTimeout: number;
    timeout: number;
    debugMode?: boolean;
}> = ({ promptTimeout, timeout, debugMode }) => {
    const [remaining, setRemaining] = useState<number>(
        (timeout + promptTimeout) / 1000
    );
    const [open, setOpen] = useState<boolean>(false);

    const onIdle = () => {
        setOpen(false);
        window.location.href = `/logout/?next=${window.location.pathname}`;
    };

    const onActive = () => {
        setOpen(false);
    };

    const onPrompt = () => {
        setOpen(true);
    };
    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptTimeout,
        throttle: 500,
        crossTab: true,
        syncTimers: 200,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    const timeTillPrompt = Math.max(remaining - promptTimeout / 1000, 0);
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

    return (
        <>
            {debugMode ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 2000,
                        background: 'rgba(255,0,0,0.45)',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '0 10px 0 0',
                    }}
                >
                    {timeTillPrompt > 0 && (
                        <span>
                            {timeTillPrompt} {seconds} until auto-logout prompt
                        </span>
                    )}
                </div>
            ) : null}
            {open && (
                <div
                    /* We have to wrap this in a "go above everything" div due to chance other modals are open */
                    style={{
                        zIndex: 2000,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <Modal
                        isOpen
                        dialogClassName="tg-modal-dialog modal-full  auto-logout-modal"
                        onCancel={activate}
                    >
                        <Modal.Header addClose={false}>
                            {interpolate(
                                gettext('You will be logged out in %s seconds'),
                                [remaining]
                            )}
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {gettext(
                                    'To keep your account secure, the system will log you out.'
                                )}
                            </p>
                            <p>{gettext('To continue, press “Continue”.')}</p>
                        </Modal.Body>
                        <div className="tg-modal-footer">
                            <Button
                                bsStyle="secondary"
                                className="btn btn-secondary btn-half"
                                onClick={() => {
                                    onIdle();
                                }}
                            >
                                {gettext('Log out')}
                            </Button>
                            <Button
                                bsStyle="success"
                                className="btn btn-success btn-half"
                                onClick={() => {
                                    activate();
                                    setOpen(false);
                                }}
                            >
                                {gettext('Continue')}
                            </Button>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
};

export const AutoLogoutWrapper: React.FC<{}> = () => {
    const {
        auto_logout: {
            AUTO_LOGOUT_TIMEOUT: timeout,
            AUTO_LOGOUT_PROMPT_TIMEOUT: promptTimeout,
            AUTO_LOGOUT_ENABLED: enabled,
            AUTO_LOGOUT_ONLY_FOR_PIN_AUTH: forPinAuthOnly,
        },
        user: { debug_mode_enabled: debugMode, using_pin_auth: usingPinAuth },
    } = useDJConst();

    if (!enabled) {
        return null;
    }

    if (forPinAuthOnly && !usingPinAuth) {
        return null;
    }

    return (
        <AutoLogout
            timeout={timeout}
            promptTimeout={promptTimeout}
            debugMode={debugMode}
        />
    );
};
