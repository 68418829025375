import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'react-fa/lib/Icon';

import { startTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';
import { gettext } from 'core/utils/text';

import styles from './Styles.scss';

const StartButton = ({ workOrderId, startTimeTracker }) => (
    <button
        onClick={(e) => {
            e.stopPropagation();
            startTimeTracker({ work_order: workOrderId });
        }}
    >
        <Icon
            className={styles.icon}
            name="play-circle"
            aria-label={gettext('Start time tracker')}
        />
    </button>
);

StartButton.propTypes = {
    workOrderId: PropTypes.number.isRequired,
    startTimeTracker: PropTypes.func.isRequired,
};

export default connect(null, { startTimeTracker: startTimeTrackerRoutine })(
    StartButton,
);
