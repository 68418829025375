import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'react-fa/lib/Icon';
import classNames from 'classnames';
import moment from 'moment';

import { gettext } from 'core/utils/text';
import { workOrderDenormalizedPropType } from 'parts/workplan/propTypes';
import { PartProductPropType } from 'parts/propTypes';
import { timeTrackersSelectors } from 'parts/operations/ducks/timeTrackers';
import { WORK_ORDER_STATUS } from 'parts/workplan/constants';
import { workOrdersSelectors } from 'parts/workplan/ducks/workOrders';
import { timeTrackerPropType } from 'parts/operations/propTypes';
import PartProductComment from 'parts/components/PartProductComment';

import {
    AffixDowntimeButton,
    PauseButton,
    SolveDowntimeButton,
    StartButton,
    StopButton,
} from './WorkOrderButtons';
import styles from './WorkOrderHeader.scss';

const WorkOrderHeader = ({
    workOrder,
    partProduct,
    isExpanded,
    // Redux
    workOrderStatus,
    newestTracker,
    oldestTracker,
}) => {
    const isRunning = workOrderStatus === WORK_ORDER_STATUS.RUNNING;
    const isFinished = workOrderStatus === WORK_ORDER_STATUS.DONE;
    const isPaused = workOrderStatus === WORK_ORDER_STATUS.PAUSED;
    const hasDowntime = workOrderStatus === WORK_ORDER_STATUS.DOWNTIME;

    const oldestStartTime = oldestTracker
        ? moment(oldestTracker.start_time)
        : null;
    const newestStartTime = newestTracker
        ? moment(newestTracker.start_time)
        : null;
    const latestPauseTime =
        newestTracker && newestTracker.end_time
            ? moment(newestTracker.end_time)
            : null;

    return (
        <div className={styles.header}>
            {!isFinished && (
                <div className={styles.arrowCol}>
                    <Icon
                        className={classNames(styles.icon, {
                            [styles.arrowUp]: isExpanded,
                        })}
                        name={isExpanded ? 'arrow-up' : 'arrow-down'}
                    />
                </div>
            )}

            <div className={styles.infoCol}>
                <div>
                    <b>
                        {partProduct.order_number} {partProduct.client.name}
                    </b>
                </div>
                <div>{partProduct.order_row.description}</div>
                {partProduct.comment && (
                    <div className={styles.comment}>
                        <PartProductComment>
                            {partProduct.comment}
                        </PartProductComment>
                    </div>
                )}
            </div>

            <div className={styles.timeCol}>
                <table>
                    <tbody>
                        <tr>
                            <th>{gettext('Order deadline')}:</th>
                            <th>
                                {moment(workOrder.end_time).format(
                                    'DD.MM.YYYY HH:mm',
                                )}
                            </th>
                        </tr>
                        <tr
                            className={classNames({
                                invisible: !newestStartTime,
                            })}
                        >
                            <td>{gettext('Actual original start time')}:</td>
                            <td>
                                {oldestStartTime?.format('DD.MM.YYYY HH:mm')}
                            </td>
                        </tr>
                        <tr
                            className={classNames({
                                invisible: !newestStartTime,
                            })}
                        >
                            <td>{gettext('Latest start')}:</td>
                            <td>
                                {newestStartTime?.format('DD.MM.YYYY HH:mm')}
                            </td>
                        </tr>
                        <tr
                            className={classNames({
                                invisible: !latestPauseTime,
                            })}
                        >
                            <td>{gettext('Last pause time')}:</td>
                            <td>
                                {latestPauseTime?.format('DD.MM.YYYY HH:mm')}
                            </td>
                        </tr>
                        <tr>
                            <td>{gettext('Worker')}:</td>
                            <td>
                                {DJ_CONST.WORKERS_MAP
                                    ? DJ_CONST.WORKERS_MAP[workOrder.worker]
                                    : workOrder.worker}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {!isFinished && (
                <div className={styles.controlCol}>
                    <PauseButton
                        latestTrackerId={newestTracker?.id}
                        isRunning={isRunning}
                    />
                    <AffixDowntimeButton
                        workOrderId={workOrder.id}
                        isRunning={isRunning}
                    />
                    {hasDowntime && (
                        <SolveDowntimeButton workOrderId={workOrder.id} />
                    )}
                    {(!(isRunning || isFinished || hasDowntime) ||
                        isPaused) && <StartButton workOrderId={workOrder.id} />}
                    {isRunning && (
                        <StopButton latestTrackerId={newestTracker?.id} />
                    )}
                </div>
            )}
        </div>
    );
};

WorkOrderHeader.propTypes = {
    workOrder: workOrderDenormalizedPropType.isRequired,
    workOrderStatus: PropTypes.oneOf(Object.values(WORK_ORDER_STATUS))
        .isRequired,
    partProduct: PartProductPropType.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    newestTracker: timeTrackerPropType,
    oldestTracker: timeTrackerPropType,
};

const mapStateToProps = (state, ownProps) => ({
    workOrderStatus: workOrdersSelectors.getWorkOrderStatus(
        state,
        ownProps.workOrder.id,
    ),
    newestTracker: timeTrackersSelectors.getNewestTracker(
        state,
        ownProps.workOrder.id,
    ),
    oldestTracker: timeTrackersSelectors.getOldestTracker(
        state,
        ownProps.workOrder.id,
    ),
});

export default connect(mapStateToProps, null)(WorkOrderHeader);
